"use client";

import { useEffect, useState } from "react";

type Returns = {
  isMobileScreen: boolean;
  isTabletScreen: boolean;
  isDesktopScreen: boolean;
  windowWidth: number;
};
export const useCheckWindowSize = (): Returns => {
  const windowWidth = window.innerWidth;
  const [currentWindowWidth, setCurrentWindowWidth] = useState(windowWidth);
  const [deviceSizeInfo, setDeviceSizeInfo] = useState({
    isMobileScreen: currentWindowWidth < 768,
    isTabletScreen: currentWindowWidth < 1200 && currentWindowWidth >= 768,
    isDesktopScreen: currentWindowWidth >= 1200,
  });

  useEffect(() => {
    const changeCurrentWindowWidth = () => {
      const currentWidth = window.innerWidth;
      setCurrentWindowWidth(currentWidth);
    };
    changeCurrentWindowWidth();
    window.addEventListener("resize", changeCurrentWindowWidth);
    return () => window.removeEventListener("resize", changeCurrentWindowWidth);
  }, []);

  useEffect(() => {
    const { isMobileScreen, isTabletScreen, isDesktopScreen } = deviceSizeInfo;
    if (
      (isMobileScreen && currentWindowWidth >= 768) ||
      (isTabletScreen &&
        (currentWindowWidth < 768 || currentWindowWidth >= 1200)) ||
      (isDesktopScreen && currentWindowWidth < 1200)
    ) {
      setDeviceSizeInfo({
        isMobileScreen: currentWindowWidth < 768,
        isTabletScreen: currentWindowWidth < 1200 && currentWindowWidth >= 768,
        isDesktopScreen: currentWindowWidth >= 1200,
      });
    }
  }, [currentWindowWidth, deviceSizeInfo]);

  return {
    isMobileScreen: deviceSizeInfo.isMobileScreen,
    isTabletScreen: deviceSizeInfo.isTabletScreen,
    isDesktopScreen: deviceSizeInfo.isDesktopScreen,
    windowWidth: currentWindowWidth,
  };
};
