import { useState } from "react";

import { changeRAndDUpperCase } from "@frontend/common";

import { TargetPressData } from "@api/dealsSimilarData/dealsSimilarData.type";
import { formatDate } from "@utils/formatDate";

import * as S from "./TargetPressTableContainer.style";

const TargetPressTableContainer = (data: TargetPressData) => {
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);

  return (
    <S.TargetPressTableContainer>
      <S.TargetPressTableTitle>Target Press</S.TargetPressTableTitle>
      <S.TargetPressTableWrapper>
        <S.TargetPressTableHeader>
          <S.TargetPressTablePressId>
            <S.TargetPressTableHeadDiv>Press ID</S.TargetPressTableHeadDiv>
          </S.TargetPressTablePressId>
          <S.TargetPressTablePressDate>
            <S.TargetPressTableHeadDiv>Press Date</S.TargetPressTableHeadDiv>
          </S.TargetPressTablePressDate>
          <S.TargetPressTableSeller>
            <S.TargetPressTableHeadDiv>Seller</S.TargetPressTableHeadDiv>
          </S.TargetPressTableSeller>
          <S.TargetPressTableBuyer>
            <S.TargetPressTableHeadDiv>Buyer</S.TargetPressTableHeadDiv>
          </S.TargetPressTableBuyer>
          <S.TargetPressTableDealType>
            <S.TargetPressTableHeadDiv>Deal Type</S.TargetPressTableHeadDiv>
          </S.TargetPressTableDealType>
          <S.TargetPressTablePressTitle>
            <S.TargetPressTableHeadDiv> Press Title</S.TargetPressTableHeadDiv>
          </S.TargetPressTablePressTitle>
        </S.TargetPressTableHeader>

        <S.TargetPressTableBody
          key={data?.pressId}
          onMouseEnter={() => setHoveredRow(data?.pressId)}
          onMouseLeave={() => setHoveredRow(null)}
          hovered={hoveredRow === data?.pressId}
        >
          <S.TargetPressTablePressId>
            <span> #{data?.pressId}</span>
          </S.TargetPressTablePressId>

          <S.TargetPressTablePressDateData>
            <S.TargetPressTableBodyDiv>
              <div> {formatDate(data?.pressDate[0]?.text, "YYYY.MM.DD")}</div>
              <div>{data?.pressDate[0].isManual ? "(Manual)" : ""}</div>
            </S.TargetPressTableBodyDiv>
          </S.TargetPressTablePressDateData>

          <S.TargetPressTableSellerData>
            <S.TargetPressTableBodyDiv>
              {data?.seller.map((seller) => (
                <div>{seller.text}</div>
              ))}
            </S.TargetPressTableBodyDiv>
          </S.TargetPressTableSellerData>
          <S.TargetPressTableBuyerData>
            <S.TargetPressTableBodyDiv>
              {data?.buyer.map((buyer) => (
                <div>{buyer.text}</div>
              ))}
            </S.TargetPressTableBodyDiv>
          </S.TargetPressTableBuyerData>
          <S.TargetPressTableDealTypeData>
            <S.TargetPressTableBodyDiv>
              {data?.dealType.map((dealType) => (
                <div>{changeRAndDUpperCase(dealType.text)}</div>
              ))}
            </S.TargetPressTableBodyDiv>
          </S.TargetPressTableDealTypeData>
          <S.TargetPressTablePressTitleData>
            <S.TargetPressTablePressTitleOverflow>
              {data?.pressTitle}
            </S.TargetPressTablePressTitleOverflow>
          </S.TargetPressTablePressTitleData>
        </S.TargetPressTableBody>
      </S.TargetPressTableWrapper>
    </S.TargetPressTableContainer>
  );
};

export default TargetPressTableContainer;
