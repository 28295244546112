import styled from "styled-components";

export const CheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 34.4rem;
`;

export const Checkbox = styled.input`
  all: unset;
  display: flex;
  width: var(--Size-Icon-16, 1rem);
  height: var(--Size-Icon-16, 1rem);
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--Border-Radius-2, 0.125rem);
  border: 1.5px solid
    var(--Color-Common-Border-Interactive-Secondary-Hovered, #96a1b0);
  background: var(--Color-Common-Bg-Primary, #fff);
  cursor: pointer;

  &:checked {
    background: var(--Color-Common-Bg-Interactive-Primary-Hovered, #0198e9);
    border: 1.5px solid #0198e9;
  }
`;

export const CheckIcon = styled.div<{ $isSelected: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ $isSelected }) => ($isSelected ? 1 : 0)};
  visibility: ${({ $isSelected }) => ($isSelected ? "visible" : "hidden")};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  svg {
    opacity: ${({ $isSelected }) => ($isSelected ? 1 : 0)};
    visibility: ${({ $isSelected }) => ($isSelected ? "visible" : "hidden")};
  }
`;
