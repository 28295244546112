import React, { useState } from "react";

import { EDITOR_INFO_FILTER } from "./components/PressListEditorInfoSearch/constants/editorInfoFilter.constants";
import PressListEditorInfoSearch from "./components/PressListEditorInfoSearch/PressListEditorInfoSearch";
import { PRESS_INFO_FILTER } from "./components/PressListPressInfoSearch/constants/pressInfoFilter.constants";
import PressListPressInfoSearch from "./components/PressListPressInfoSearch/PressListPressInfoSearch";
import * as S from "./PressListSearch.style";

const PressListSearch = () => {
  const [currentPressFilter, setCurrentPressFilter] = useState(
    PRESS_INFO_FILTER[0],
  );
  const [currentEditorFilter, setCurrentEditorFilter] = useState(
    EDITOR_INFO_FILTER[0],
  );

  return (
    <S.SearchWrapper>
      <PressListPressInfoSearch
        currentPressFilter={currentPressFilter}
        setCurrentFilter={setCurrentPressFilter}
      />
      <PressListEditorInfoSearch
        currentEditorFilter={currentEditorFilter}
        setCurrentFilter={setCurrentEditorFilter}
      />
    </S.SearchWrapper>
  );
};

export default PressListSearch;
