import ToastErrorBoundary from "@/components/shared/business/toastErrorBoundary/toastErrorBoundary";
import {
  useArticleInfoMutation,
  useArticleInfoQuery,
} from "@api/articleInfo/useArticleInfo";
import { useSimilarDataQuery } from "@api/dealsSimilarData/dealsSimilarData";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import { useModalityCategoryUpdate } from "@FillInTableContainer/components/FillInTable/hooks/useModalityCategoryUpdate";
import { SaveToDBModalContainer } from "@FillInTableContainer/shared";
import { useTableCreatorFormatErrorPressId } from "@FillInTableContainer/shared/context/TableCreatorFormatErrorPressIdContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import { useCreateMainLabel } from "@hooks/useCreateMainLabel";
import useModal from "@hooks/useModal";
import { useMovePage } from "@hooks/useMovePage";
import { useResetArticleContent } from "@hooks/useResetArticleContent";
import { useUndoRedo } from "@hooks/useUndoRedo";
import { useUpdateEntryData } from "@hooks/useUpdateArticleData";
import IconArrowLeftDisable from "@images/common/icon-arrow-left-gray.svg";
import IconArrowLeft from "@images/common/icon-arrow-left.svg";
import IconArrowRightDisable from "@images/common/icon-arrow-right-gray.svg";
import IconArrowRight from "@images/common/icon-arrow-right.svg";
import IconStrokeCheck from "@images/common/icon-stroke-check.svg";
import IconSavedToStoreFullDisable from "@images/fillInTable/tableCreator/icon-saved-to-store-full-gray.svg";
import IconSavedToStoreFull from "@images/fillInTable/tableCreator/icon-saved-to-store-full.svg";
import { useFillInTableRoles } from "@libs/roles/hooks/useFillInTableRoles";
import PublishablePressCheckbox from "@organisms/PublishablePressCheckbox/PublishablePressCheckbox";
import { useEntryDataValue } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import * as S from "./DealsBottomButtonContainer.style";
import { useCheckValidationInDealsTableCreator } from "./hooks/useCheckValidationInDealsTableCreator";
import FillInTableContentsFabContainer from "../../../FillInTableContentsFabContainer/FillInTableContentsFabContainer";
import SimilarDataButtonContainer from "../SimilarDataButtonContainer/SimilarDataButtonContainer";

const DealsBottomButtonContainer = () => {
  const draftArticleId = getPressIdFromURL();

  // NOTE : DataFormat으로 에러가 발생한 경우, Bottom Button을 비활성화 시키기 위한 로직
  const { errorPressId } = useTableCreatorFormatErrorPressId();
  const isDisabledPress = errorPressId === draftArticleId;

  const entryData = useEntryDataValue(draftArticleId);

  const { data: articleInfo } = useArticleInfoQuery(Number(draftArticleId));
  const { mutateAsync: updateArticleInfo } = useArticleInfoMutation(
    Number(draftArticleId),
  );
  const updateEntryData = useUpdateEntryData();

  const { updateModalityCategoryValue } = useModalityCategoryUpdate();
  const { createMainLabel } = useCreateMainLabel();

  const status = articleInfo?.draftArticleStatus;

  const movePage = useMovePage();

  const { handleCheckValidationFull } = useCheckValidationInDealsTableCreator();
  const { closeModal, isModal: isModalOpen, openModal } = useModal();

  const { data, isError } = useSimilarDataQuery(draftArticleId);

  const {
    closeModal: closeCheckModal,
    isModal: isCheckModalOpen,
    openModal: openCheckModal,
  } = useModal();

  const {
    hasFullButtonRoles,
    hasNextPageAccessRoles,
    hasPrevPageAccessRoles,
    isSuperAdmin,
  } = useFillInTableRoles();

  const resetArticleContent = useResetArticleContent();

  const { currentHistory } = useTableCreatorCurrentHistory();

  const { undo, redo, isDisabledRedo, isDisabledUndo, resetHistory } =
    useUndoRedo({
      state: currentHistory,
    });

  const undoRedoEvents = {
    undo,
    redo,
    isDisabledRedo,
    isDisabledUndo,
  };

  const moveNext = async () => {
    if (handleCheckValidationFull()) {
      await updateArticleInfo({
        draftArticleStatus: DRAFT_STATUS_CODE.insertImage,
      });
      movePage("insertImage");
    }
  };

  const movePrev = async () => {
    movePage("pressClassification");
  };

  const saveToDBFull = async () => {
    const ftEditor2Name = articleInfo?.ftEditor2Name;

    await updateEntryData(entryData, true);

    if (ftEditor2Name === null || ftEditor2Name === "") {
      await updateArticleInfo({
        draftArticleStatus: DRAFT_STATUS_CODE.dbSaved,
        type: "FT2",
        isCheckStartDate: "true",
      });
    } else {
      await updateArticleInfo({
        draftArticleStatus: DRAFT_STATUS_CODE.dbSaved,
      });
    }
    updateModalityCategoryValue();
    await resetArticleContent();
    await createMainLabel();
    resetHistory();
  };

  const reviewInProgressFull = () => {
    updateArticleInfo({
      draftArticleStatus: DRAFT_STATUS_CODE.reviewInProgress,
    });
  };

  const handleClickSaveToDBFull = () => {
    if (handleCheckValidationFull()) {
      openModal();
    }
  };

  const isVisibleFullSaveToDB = status === DRAFT_STATUS_CODE.reviewInProgress;

  const isPressClassificationButtonDisabled = !hasPrevPageAccessRoles;

  const isAbleSuperAdminButton =
    (status === DRAFT_STATUS_CODE.insertImage ||
      status === DRAFT_STATUS_CODE.editArticle ||
      status === DRAFT_STATUS_CODE.dbSaved ||
      status === DRAFT_STATUS_CODE.published) &&
    isSuperAdmin;

  const isFullButtonDisabled =
    (status === undefined ||
      (status !== DRAFT_STATUS_CODE.reviewInProgress &&
        status !== DRAFT_STATUS_CODE.dbSaved) ||
      !hasFullButtonRoles) &&
    !isAbleSuperAdminButton;

  const isInsertImageButtonDisabled =
    status === undefined ||
    status === DRAFT_STATUS_CODE.aiEntryDone ||
    status === DRAFT_STATUS_CODE.reviewInProgress ||
    !hasNextPageAccessRoles;

  const isSimilarPressEnable =
    data["Similar Press"].length !== 0 &&
    ![
      "S111",
      DRAFT_STATUS_CODE.aiEntryInProgress,
      DRAFT_STATUS_CODE.notForAiEntry,
    ].includes(status);

  return (
    <>
      <S.LeftButtonWrapper>
        <S.PrevButton
          onClick={movePrev}
          disabled={isPressClassificationButtonDisabled}
        >
          <img
            src={
              isPressClassificationButtonDisabled
                ? IconArrowLeftDisable
                : IconArrowLeft
            }
            alt="go to press list"
          />
          Press Class
        </S.PrevButton>
        <S.PublishableButtonWrapper>
          <PublishablePressCheckbox />
        </S.PublishableButtonWrapper>

        <ToastErrorBoundary>
          {isSimilarPressEnable && !isError && (
            <SimilarDataButtonContainer
              openCheckModal={openCheckModal}
              isCheckModalOpen={isCheckModalOpen}
              closeCheckModal={closeCheckModal}
              data={data}
            />
          )}
        </ToastErrorBoundary>
      </S.LeftButtonWrapper>
      <S.RightButtonWrapper>
        <FillInTableContentsFabContainer undoRedoEvents={undoRedoEvents} />

        {isVisibleFullSaveToDB ? (
          <>
            <SaveToDBModalContainer
              closeModal={closeModal}
              isModalOpen={isModalOpen}
              saveToDB={saveToDBFull}
              hasNextRoles={hasNextPageAccessRoles}
            />
            <S.NextButton
              onClick={handleClickSaveToDBFull}
              disabled={isFullButtonDisabled || isDisabledPress}
            >
              Save to DB (Full)&nbsp;
              <img
                src={
                  isFullButtonDisabled || isDisabledPress
                    ? IconSavedToStoreFullDisable
                    : IconSavedToStoreFull
                }
                alt="store to DB"
              />
            </S.NextButton>
          </>
        ) : (
          <S.ReviewInProgressButton
            onClick={reviewInProgressFull}
            disabled={isFullButtonDisabled || isDisabledPress}
          >
            Review in Progress (Full)
            <img src={IconStrokeCheck} alt="Review in Progress" />
          </S.ReviewInProgressButton>
        )}

        <S.NextButton onClick={moveNext} disabled={isInsertImageButtonDisabled}>
          Insert Image&nbsp;
          <img
            src={
              isInsertImageButtonDisabled
                ? IconArrowRightDisable
                : IconArrowRight
            }
            alt="go to frame Edit"
          />
        </S.NextButton>
      </S.RightButtonWrapper>
    </>
  );
};

export default DealsBottomButtonContainer;
