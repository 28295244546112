"use client";

import styled, { css } from "styled-components";

export const TableBody = styled.div<{ $minHeight?: string }>`
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: var(--Border-Radius-8, 0.8rem);
  border-bottom-right-radius: var(--Border-Radius-8, 0.8rem);

  background: var(--Color-Common-Bg-Interactive-Secondary, #fff);
  border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  border-top: none;

  ${({ $minHeight }) =>
    $minHeight &&
    css`
      min-height: ${$minHeight};
    `}
`;
