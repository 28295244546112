import React, { useCallback, useEffect, useState } from "react";

export type UseResizeModalReturn = {
  modalSizeHeight: number;
  modalSizeWidth: number;
  onMouseDownResize: (
    e: React.MouseEvent,
    direction: "top" | "bottom" | "left" | "right" | "bottom-right",
  ) => void;
};

const SIZE = {
  minHeight: 320,
  minWidth: 896,
  maxHeight: 480,
  defaultWidth: 1200,
};

export const useExpandModal = (targetRef: React.RefObject<HTMLDivElement>) => {
  const [isResizing, setIsResizing] = useState(false);
  const [initHeight, setInitHeight] = useState(0);
  const [isResizingClicked, setIsResizingClicked] = useState(false);
  const [mouseDownPosition, setMouseDownPosition] = useState({ x: 0, y: 0 });

  const [modalSizeHeight, setModalSizeHeight] = useState(0);
  const [modalSizeWidth, setModalSizeWidth] = useState(SIZE.defaultWidth);
  const [resizeDirection, setResizeDirection] = useState<
    "top" | "bottom" | "left" | "right" | "bottom-right" | null
  >(null);

  const onMouseDownResize = (
    e: React.MouseEvent,
    direction: "top" | "bottom" | "left" | "right" | "bottom-right",
  ) => {
    e.preventDefault();

    setIsResizing(true);
    setIsResizingClicked(true);
    setResizeDirection(direction);
    setMouseDownPosition({ x: e.clientX, y: e.clientY });
  };

  const onMouseMoveResize = useCallback(
    (e: MouseEvent) => {
      if (!isResizing || !targetRef.current || !resizeDirection) return;

      const deltaX = e.clientX - mouseDownPosition.x;
      const deltaY = e.clientY - mouseDownPosition.y;

      let newHeight = modalSizeHeight;
      let newWidth = modalSizeWidth;

      if (resizeDirection === "top") {
        newHeight = modalSizeHeight - deltaY;
      } else if (resizeDirection === "bottom") {
        newHeight = modalSizeHeight + deltaY;
      }

      if (resizeDirection === "left") {
        newWidth = modalSizeWidth - deltaX;
      } else if (resizeDirection === "right") {
        newWidth = modalSizeWidth + deltaX;
      } else if (resizeDirection === "bottom-right") {
        newHeight = modalSizeHeight + deltaY;
        newWidth = modalSizeWidth + deltaX;
      }

      if (newHeight >= SIZE.minHeight) setModalSizeHeight(newHeight);
      if (newWidth >= SIZE.minWidth) setModalSizeWidth(newWidth);

      setMouseDownPosition({ x: e.clientX, y: e.clientY });

      document.body.style.cursor =
        resizeDirection.includes("bottom") || resizeDirection.includes("top")
          ? "row-resize"
          : "col-resize";
      if (resizeDirection === "bottom-right")
        document.body.style.cursor = "nwse-resize";
    },
    [
      isResizing,
      mouseDownPosition,
      resizeDirection,
      modalSizeHeight,
      modalSizeWidth,
      targetRef,
    ],
  );

  const onMouseUpResize = useCallback(() => {
    setIsResizing(false);
    setResizeDirection(null);
    document.body.style.cursor = "default";
  }, []);

  useEffect(() => {
    document.addEventListener("mousemove", onMouseMoveResize);
    document.addEventListener("mouseup", onMouseUpResize);

    return () => {
      document.removeEventListener("mousemove", onMouseMoveResize);
      document.removeEventListener("mouseup", onMouseUpResize);
      document.body.style.cursor = "default";
    };
  }, [onMouseMoveResize, onMouseUpResize]);

  useEffect(() => {
    if (targetRef.current) {
      const initialHeight = targetRef.current.scrollHeight;
      setModalSizeHeight(initialHeight);
      setInitHeight(initialHeight);
    }
  }, [targetRef]);

  return {
    initHeight,
    modalSizeHeight,
    modalSizeWidth,
    onMouseDownResize,
    isResizingClicked,
    setModalSizeWidth,
    setModalSizeHeight,
  };
};
