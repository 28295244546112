import { useEffect } from "react";

import { IconDrag, IconMenu, IconXMark } from "@frontend/common";

import { SimilarDataResponse } from "@api/dealsSimilarData/dealsSimilarData.type";

import * as S from "./SimilarDataModalContainer.style";
import { useDraggedModal } from "../DealsBottomButtonContainer/hooks/useDraggedModal";
import { useExpandModal } from "../DealsBottomButtonContainer/hooks/useExpandModal";
import SimilarPressTableContainer from "../SimilarPressTableContainer/SimilarPressTableContainer";
import TargetPressTableContainer from "../TargetPressTableContainer/TargetPressTableContainer";

type Props = {
  data: SimilarDataResponse;
  closeCheckModal: () => void;
  isButtonClicked: boolean;
};

const SimilarDataModalContainer = ({
  isButtonClicked,
  data,
  closeCheckModal,
}: Props) => {
  const { draggedTargetRef, draggedPosition, setDraggedPosition, onMouseDown } =
    useDraggedModal();

  const {
    initHeight,
    modalSizeHeight,
    modalSizeWidth,
    setModalSizeWidth,
    setModalSizeHeight,
    onMouseDownResize,
    isResizingClicked: isResizing,
  } = useExpandModal(draggedTargetRef);

  const resetModalPositionAndSize = () => {
    setModalSizeWidth(1200);
    setModalSizeHeight(initHeight);

    const initialX = (window.innerWidth - 1200) / 2;
    const initialY = (window.innerHeight - initHeight) / 2;

    setDraggedPosition({ x: initialX, y: initialY });
  };

  useEffect(() => {
    resetModalPositionAndSize();

    // NOTE: 버튼 눌릴때마다 초기화
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isButtonClicked, initHeight]);

  return (
    <S.FillInTableSimilarDataModalWrapper
      ref={draggedTargetRef}
      draggedPosition={draggedPosition}
      modalSizeHeight={modalSizeHeight}
      modalSizeWidth={modalSizeWidth}
    >
      <S.FillInTableSimilarDataModalInnerWrapper>
        <S.ResizeBorder
          onMouseDown={(e) => onMouseDownResize(e, "top")}
          isTopBorder
        />
        <S.FillInTableModalHeadLine onMouseDown={onMouseDown}>
          <IconMenu width={20} height={20} />
          <S.DragBox>Drag</S.DragBox>
          <S.ModalTitle>Overlapped Entry Data Alert</S.ModalTitle>
          <S.CloseBox onClick={() => closeCheckModal()}>
            <span>Close</span>
            <IconXMark width={20} height={20} color="#e53e06" />
          </S.CloseBox>
        </S.FillInTableModalHeadLine>

        <S.FillInTableContentContainer isResizing={isResizing}>
          <TargetPressTableContainer {...data["Target Press"]} />
          <SimilarPressTableContainer
            targetPressData={data["Target Press"]}
            similarPressData={data["Similar Press"]}
            isResizing={isResizing}
          />
        </S.FillInTableContentContainer>

        <S.ResizeBorder
          onMouseDown={(e) => onMouseDownResize(e, "bottom-right")}
        >
          <IconDrag width={16} height={16} />
        </S.ResizeBorder>
      </S.FillInTableSimilarDataModalInnerWrapper>
    </S.FillInTableSimilarDataModalWrapper>
  );
};

export default SimilarDataModalContainer;
