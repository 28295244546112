import { SECTION_CODE_BY_NAME } from "@frontend/common";

import { EntryDataValueInfo } from "@/types/clinicalTrials.types";
import { Regulatory } from "@/types/regulatory.types";
import { removeStartBrackets } from "@utils/removeBrackets";

import { ArticleMainLabelList } from "./articleMainLabelList";
import { transformTextListFromEntryDataValue } from "../../../utils/transformTextListFromEntryDataValue";
import { MAIN_LABEL_VALUE_REGULATORY } from "../../constants/mainLabel.constants";
import {
  FormattedMainLabelListType,
  MainLabelArticleSectionCode,
  MainLabelEntityTypes,
  MainLabelFormatTypes,
  MainLabelInfoData,
  MainLabelListForUpdateType,
  MainLabelListType,
  MainLabelNames,
  RegulatoryMainLabelNames,
} from "../../types/articleMainLabel.types";

export class RegulatoryMainLabelList extends ArticleMainLabelList {
  #targetSection = "Regulatory" as const;

  mainLabelList: MainLabelListType;

  mainLabelPathOfEntryData: Record<
    string,
    (data: { Regulatory?: Partial<Regulatory> }) => string[]
  >;

  constructor() {
    super();
    this.mainLabelPathOfEntryData = MAIN_LABEL_VALUE_REGULATORY;
    this.mainLabelList = [];
  }

  public getArticleMainLabelList(): MainLabelListType {
    return this.mainLabelList;
  }

  // eslint-disable-next-line class-methods-use-this
  public getFormattedMainLabelList(
    defaultMainLabelList: MainLabelInfoData,
    mainLabelList: MainLabelListType,
  ): FormattedMainLabelListType {
    const formattedLabelList = defaultMainLabelList.map((labelInfo) => {
      const targetValueList = mainLabelList
        .filter((label) => label.labelName === labelInfo.labelName)
        .map((label) => label.labelValue);

      return {
        ...labelInfo,
        value: [...new Set(targetValueList)] || [],
      };
    });

    return formattedLabelList;
  }

  public createMainLabelList(
    defaultMainLabelList: MainLabelInfoData,
    entryData: {
      Regulatory?: Partial<Regulatory>;
    },
  ): MainLabelListForUpdateType {
    const temp: MainLabelListForUpdateType = [];
    defaultMainLabelList.forEach((labelInfo) => {
      const targetValue =
        labelInfo.type === "MLT005"
          ? this.mainLabelPathOfEntryData[labelInfo.labelName](
              entryData,
            )?.filter((_, index) => index === 0)
          : this.mainLabelPathOfEntryData[labelInfo.labelName](entryData);
      if (targetValue) {
        targetValue.map((value) =>
          temp.push({
            ...labelInfo,
            value,
            id: "",
          }),
        );
      }
    });

    return temp;
  }

  public getTargetOptionList(
    labelName: MainLabelNames,
    entryData: {
      Regulatory?: Partial<Regulatory>;
    },
  ): string[] {
    const REGULATORY_TARGET_VALUE: Record<
      RegulatoryMainLabelNames,
      EntryDataValueInfo[] | []
    > = {
      Modality:
        entryData?.[this.#targetSection]?.[
          "Drug Profile"
        ]?.[0]?.Modality?.value?.map((item) => ({
          ...item,
          text: item.text.replace("(etc)", "").trim(),
        })) || [],
      Target:
        entryData?.[this.#targetSection]?.[
          "Drug Profile"
        ]?.[0]?.Target.value?.map((item) => {
          return {
            text: removeStartBrackets(item.text),
            is_modified: item.is_modified,
          };
        }) || [],
      "Drug Name": [],
      "Marketing Status": [],
      "Target Indication":
        entryData?.[this.#targetSection]?.["Drug Profile"]?.[0]?.[
          "Target Indication"
        ]?.value || [],
      "Application Type": [],
    };

    const MAIN_LABEL_OPTION_LIST: Record<RegulatoryMainLabelNames, string[]> = {
      Modality: REGULATORY_TARGET_VALUE.Modality
        ? transformTextListFromEntryDataValue(REGULATORY_TARGET_VALUE.Modality)
        : [],
      Target: REGULATORY_TARGET_VALUE.Target
        ? transformTextListFromEntryDataValue(REGULATORY_TARGET_VALUE.Target)
        : [],
      "Drug Name": [],
      "Marketing Status": [],
      "Target Indication": REGULATORY_TARGET_VALUE["Target Indication"]
        ? transformTextListFromEntryDataValue(
            REGULATORY_TARGET_VALUE["Target Indication"],
          )
        : [],
      "Application Type": [],
    };

    return MAIN_LABEL_OPTION_LIST[labelName as RegulatoryMainLabelNames];
  }

  public entryDataCompatibilityWithMainLabelsData(
    defaultMainLabelList: MainLabelInfoData,
    mainLabelList: MainLabelListType,
    entryData: {
      Regulatory?: Partial<Regulatory>;
    },
  ): Record<RegulatoryMainLabelNames, boolean> {
    const checkTargetMainLabelValue = (labelName: RegulatoryMainLabelNames) => {
      return this.getFormattedMainLabelList(
        defaultMainLabelList,
        mainLabelList,
      )?.filter((label) => label.labelName === labelName)?.[0]?.value;
    };

    const CHECK_REGULATORY_TARGET_MAIN_LABEL: Record<
      RegulatoryMainLabelNames,
      boolean
    > = {
      "Drug Name": !!(
        this.mainLabelPathOfEntryData["Drug Name"]?.(entryData)?.[0] ===
        checkTargetMainLabelValue("Drug Name")?.[0]
      ),
      Target:
        checkTargetMainLabelValue("Target")?.length === 0 ||
        checkTargetMainLabelValue("Target")?.filter((value) =>
          this.mainLabelPathOfEntryData.Target(entryData)?.includes(value),
        )?.length === checkTargetMainLabelValue("Target")?.length,
      Modality: this.mainLabelPathOfEntryData
        .Modality?.(entryData)
        .includes(checkTargetMainLabelValue("Modality")?.[0]),
      "Marketing Status": !!(
        this.mainLabelPathOfEntryData?.["Marketing Status"]?.(
          entryData,
        )?.[0] === checkTargetMainLabelValue("Marketing Status")?.[0]
      ),
      "Target Indication": this.mainLabelPathOfEntryData["Target Indication"]?.(
        entryData,
      ).includes(checkTargetMainLabelValue("Target Indication")?.[0]),
      "Application Type": !!(
        this.mainLabelPathOfEntryData["Application Type"]?.(entryData)?.[0] ===
        checkTargetMainLabelValue("Application Type")?.[0]
      ),
    };

    return CHECK_REGULATORY_TARGET_MAIN_LABEL;
  }

  public mainLabelListForEntryDataCompatibility(
    defaultMainLabelList: MainLabelInfoData,
    mainLabelList: MainLabelListType,
    entryData: {
      Regulatory?: Partial<Regulatory>;
    },
  ): MainLabelListForUpdateType | null {
    const entryDataCompatibilityWithMainLabels =
      this.entryDataCompatibilityWithMainLabelsData(
        defaultMainLabelList,
        mainLabelList,
        entryData,
      );

    const isNeedUpdate =
      Object.values(entryDataCompatibilityWithMainLabels).every(
        (value) => value,
      ) === false;

    if (!isNeedUpdate) return null;

    const needToUpdateMainLabelKeys = Object.entries(
      entryDataCompatibilityWithMainLabels,
    )
      .map(([key, value]) => {
        if (value === false) return key;
        return null;
      })
      .filter((value) => value !== null) as MainLabelNames[];

    const formattedMainLabelList = this.getFormattedMainLabelList(
      defaultMainLabelList,
      mainLabelList,
    );

    const mainLabelListForEmptyValue = needToUpdateMainLabelKeys
      ?.filter((updatedLabel) => {
        const defaultLabels = mainLabelList?.map((label) => label.labelName);

        return (
          defaultLabels.filter((label) => label === updatedLabel).length === 0
        );
      })
      .map((label) => {
        const targetLabel = formattedMainLabelList.find(
          (item) => item.labelName === label,
        );

        if (!targetLabel) return null;
        return {
          id: "",
          labelName: label,
          type: targetLabel.type,
          articleSection: targetLabel.articleSection,
          entity: targetLabel.entity,
          labelValue: "",
        };
      })
      .filter((value) => value !== null);

    const newMainLabelList = [
      ...mainLabelList,
      ...mainLabelListForEmptyValue,
    ]?.map((label) => {
      if (label === null) return;
      if (
        label.labelName === "Target" &&
        needToUpdateMainLabelKeys.includes("Target")
      ) {
        return {
          id: String(label.id),
          labelName: label.labelName,
          type: label.type,
          articleSection: label.articleSection,
          entity: label.entity,
          value: "",
        };
      }
      if (needToUpdateMainLabelKeys.find((key) => key === label.labelName)) {
        return {
          id: String(label.id),
          labelName: label.labelName,
          type: label.type,
          articleSection: label.articleSection,
          entity: label.entity,
          value:
            this.mainLabelPathOfEntryData[label.labelName]?.(entryData)?.[0],
        };
      }
      return {
        id: String(label.id),
        labelName: label.labelName,
        type: label.type,
        articleSection: label.articleSection,
        entity: label.entity,
        value: label.labelValue,
      };
    }) as {
      id: string;
      labelName: RegulatoryMainLabelNames;
      type: MainLabelFormatTypes;
      articleSection: MainLabelArticleSectionCode;
      entity: MainLabelEntityTypes;
      value: string;
    }[];

    if (needToUpdateMainLabelKeys.find((key) => key === "Target")) {
      const targetMainLabelData = this.mainLabelPathOfEntryData
        .Target?.(entryData)
        ?.map((value) => ({
          id: "",
          labelName: "Target" as RegulatoryMainLabelNames,
          type: "MLT004" as MainLabelFormatTypes,
          articleSection:
            SECTION_CODE_BY_NAME.regulatory as MainLabelArticleSectionCode,
          entity: "E020" as MainLabelEntityTypes,
          value,
        }));
      return [...newMainLabelList, ...targetMainLabelData];
    }

    return newMainLabelList.filter(
      (item) => !(item.id === "" && item.value === undefined),
    );
  }
}
