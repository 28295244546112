import React from "react";

import { DRAFT_STATUS_INFO } from "@PressListContainer/draftStatusInfo.constants";
import { PressListData } from "@PressListContainer/pressList.types";
import { checkUrlStatus } from "@utils/checkUrlStatus";

import * as S from "./PressStatus.style";

interface Props {
  data: PressListData;
  isDraftError: boolean;
  draftErrorMessage: string;
  disabled: boolean;
  createDAE: (id: number) => void;
}

const EFORTLESS_URL_PROD = "https://bio-research.ai";
const EFORTLESS_URL_DEV = "https://bio-research.dev";
const EFORTLESS_URL_LOCAL = "http://localhost:3000";

const REDIRECT_URL_ACCORDING_TO_URL_STATUS = {
  PROD: EFORTLESS_URL_PROD,
  DEV: EFORTLESS_URL_DEV,
  LOCAL: EFORTLESS_URL_LOCAL,
};

const handleOpenArticle = (urlTitle?: string | null) => {
  const urlStatus = checkUrlStatus();

  if (!urlTitle) return;

  return window.open(
    `${REDIRECT_URL_ACCORDING_TO_URL_STATUS[urlStatus]}/${urlTitle}`,
    "_blank",
  );
};

const PressStatus = ({
  data,
  isDraftError,
  draftErrorMessage,
  disabled,
  createDAE,
}: Props) => {
  if (isDraftError) {
    return (
      <S.StatusIcon className="draft-system-error" code={null}>
        <img
          src={DRAFT_STATUS_INFO.SystemError.icon}
          alt={DRAFT_STATUS_INFO.SystemError.text}
        />
        <S.StatusTooltip className="tooltip">
          {`System Error / ${draftErrorMessage}`}
        </S.StatusTooltip>
      </S.StatusIcon>
    );
  } else {
    switch (data.draftArticleStatusHighest) {
      case "S110":
      case "S112":
      case "S300":
      case "S120":
      case "S121":
      case "S910":
      case "S911":
      case "S210":
      case "S220":
        return (
          <S.StatusIcon code={data.draftArticleStatusHighest}>
            <img
              src={DRAFT_STATUS_INFO[data.draftArticleStatusHighest].icon}
              alt={DRAFT_STATUS_INFO[data.draftArticleStatusHighest].text}
            />
            <S.StatusTooltip className="tooltip">
              {DRAFT_STATUS_INFO[data.draftArticleStatusHighest].text}
            </S.StatusTooltip>
          </S.StatusIcon>
        );
      case "S920":
        return (
          <S.StatusIcon
            code={data.draftArticleStatusHighest}
            onClick={() => handleOpenArticle(data.urlTitle)}
          >
            <img
              src={DRAFT_STATUS_INFO[data.draftArticleStatusHighest].icon}
              alt={DRAFT_STATUS_INFO[data.draftArticleStatusHighest].text}
            />
            <S.StatusTooltip className="tooltip">
              {DRAFT_STATUS_INFO[data.draftArticleStatusHighest].text}
            </S.StatusTooltip>
          </S.StatusIcon>
        );
      case "S930":
        return (
          <S.StatusIcon code={data.draftArticleStatusHighest}>
            <img
              src={DRAFT_STATUS_INFO[data.draftArticleStatusHighest].icon}
              alt={DRAFT_STATUS_INFO[data.draftArticleStatusHighest].text}
            />
            <S.StatusTooltip className="tooltip">
              {["Unsuitable", data.articleRejectReason]
                .filter((item) => !!item)
                .join(" / ")}
            </S.StatusTooltip>
          </S.StatusIcon>
        );
      case "S111":
        return (
          <S.StatusIcon
            code={data.draftArticleStatusHighest}
            onClick={() => createDAE(data.pressId)}
            disabled={disabled}
          >
            <img
              src={DRAFT_STATUS_INFO[data.draftArticleStatusHighest].icon}
              alt={DRAFT_STATUS_INFO[data.draftArticleStatusHighest].text}
            />
            <S.StatusTooltip className="tooltip">
              <span>
                {`${DRAFT_STATUS_INFO[data.draftArticleStatusHighest].text}\n`}
                <b>(Click to Apply DAE)</b>
              </span>
            </S.StatusTooltip>
          </S.StatusIcon>
        );
      default:
        return null;
    }
  }
};

export default PressStatus;
