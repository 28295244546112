import React, { Component, ReactNode } from "react";

import ToastMessageContainer from "./toastMessageContainer";

interface Props {
  children?: ReactNode;
}

interface State {
  shouldHandleError: boolean;
  error: Error | null;
}

class ToastErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      shouldHandleError: false,
      error: null,
    };
  }

  public static getDerivedStateFromError(error: Error): State {
    return {
      shouldHandleError: true,
      error,
    };
  }

  public render() {
    const { shouldHandleError, error } = this.state;
    const { children } = this.props;

    if (!shouldHandleError) {
      return children;
    }

    if (shouldHandleError) {
      return (
        <ToastMessageContainer
          message={error?.message ?? "Please try again later"}
          clickButton={() => {
            this.setState({
              shouldHandleError: false,
              error: null,
            });
          }}
        />
      );
    }
    return children;
  }
}

export default ToastErrorBoundary;
