import {
  PARAGRAPH_CLINICAL_TRIALS,
  PARAGRAPH_DEALS,
  PARAGRAPH_EXPEDITED_PROGRAM,
  PARAGRAPH_REGULATORY,
  PARAGRAPH_VENTURE_CAPITAL,
} from "./paragraph.constants";

export const PARAGRAPH_ACCORDING_TO_CLASSIFICATION = {
  "": [],
  C000: PARAGRAPH_CLINICAL_TRIALS,
  C001: [],
  C003: [],
  C004: [],
  C005: PARAGRAPH_CLINICAL_TRIALS,
  C006: PARAGRAPH_DEALS,
  C007: [],
  C008: [],
  C009: [],
  C010: [],
  C011: [],
  C012: [],
  C013: PARAGRAPH_REGULATORY,
  C014: [],
  C015: [],
  C016: PARAGRAPH_EXPEDITED_PROGRAM,
  C017: [],
  C018: [],
  C019: [],
  C020: [],
  C021: PARAGRAPH_VENTURE_CAPITAL,
  C022: [],
  C023: [],
} as const;
