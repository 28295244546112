/**
 * 문장의 대괄호 문자열 제거
 */
export const removeBrackets = (text: string): string => {
  return text.replace(/\[[^\]]*\]\s*/g, "").trim();
};

/**
 * 문장 시작 부분의 대괄호 문자열 제거
 */
export const removeStartBrackets = (text: string): string => {
  return text.replace(/^\s*\[[^\]]*\]/, "").trim();
};
