import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";

import DropDownIcon from "@images/pressList/icon-dropdown.svg";
import { PressSearchFilterType } from "@PressListContainer/pressList.types";
import SearchFilterTag from "@PressListContainer/shared/components/SearchFilterTag/SearchFilterTag";

import * as S from "./PressListSearchFilterSelect.style";

interface Props {
  subTitle: string;
  filterList: PressSearchFilterType[];
  currentFilter: PressSearchFilterType;
  setCurrentFilter: Dispatch<SetStateAction<PressSearchFilterType>>;
}

const PressListSearchFilterSelect = ({
  subTitle,
  filterList,
  currentFilter,
  setCurrentFilter,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropDownRef = useRef<HTMLUListElement | null>(null);
  const selectButtonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    const handleCheckClickOutside = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const clickOutsideDropDown = !dropDownRef.current?.contains(target);
      const clickSelectButton = selectButtonRef.current?.contains(target);

      if (isOpen && clickOutsideDropDown && !clickSelectButton) {
        setIsOpen(false);
      }
    };
    window.addEventListener("click", handleCheckClickOutside);

    return () => window.removeEventListener("click", handleCheckClickOutside);
  }, [isOpen]);

  return (
    <S.FilterSelectWrapper>
      <S.SelectButton
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        ref={selectButtonRef}
      >
        <SearchFilterTag
          type="search"
          text={currentFilter.name}
          isAllInfo={currentFilter.isAllInfo}
          code={currentFilter.code}
        />

        <img width={8} src={DropDownIcon} alt="filter dropdown icon" />
      </S.SelectButton>
      {isOpen && (
        <S.DropDown ref={dropDownRef}>
          <S.FilterListTitle>{subTitle}</S.FilterListTitle>
          {filterList.map(({ key, name, isAllInfo, code }) => (
            <li key={key}>
              <button
                type="button"
                onClick={() => {
                  setCurrentFilter({ key, name, isAllInfo, code });
                  setIsOpen(false);
                }}
              >
                <SearchFilterTag
                  type="search"
                  text={name}
                  isAllInfo={isAllInfo}
                  code={code}
                />
              </button>
            </li>
          ))}
        </S.DropDown>
      )}
    </S.FilterSelectWrapper>
  );
};

export default PressListSearchFilterSelect;
