import { apiClient } from "@api/apiClient";

const API_ENDPOINT = {
  get: "/des/press-similar/deals",
};

export const similarDataApis = {
  get: async (targetPressId: string) => {
    const url = `${API_ENDPOINT.get}?pressId=${targetPressId}`;
    const { data } = await apiClient.get(url);

    return data;
  },
};
