import { styled } from "styled-components";

import {
  CommonStyle,
  PressContainerHeaderLabel,
  PressContainerBodyLabel,
} from "../SimilarPressTableContainer/SimilarPressTableContainer.style";

export const TargetPressTableContainer = styled.div`
  display: flex;
  position: relative;
  padding: var(--Spacing-8, 0.8rem);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Spacing-8, 0.8rem);
  align-self: stretch;
  border-radius: var(--Border-Radius-2, 0.2rem);
  background: var(--Color-Common-Bg-Primary, #fff);
  height: fit-content;
  width: 100%;
`;

export const TargetPressTableTitle = styled.div`
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.56rem;
`;

export const TargetPressTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
`;

export const TargetPressTableHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  border-bottom: none;
  background: var(--Color-Common-Bg-Secondary, #f0f2f4);
  height: 3.4rem;
  color: var(--Color-Common-Text-Primary, #2a2f37);
`;

export const TargetPressTableBody = styled.div<{ hovered: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  border-right: none;
  background-color: ${({ hovered }) =>
    hovered ? "#E0F4FF" : "var(--Color-Common-Bg-Interactive-Secondary, #fff)"};
`;

export const TargetPressTableHeadDiv = styled.div`
  ${PressContainerHeaderLabel}
`;

export const TargetPressTableBodyDiv = styled.div`
  ${PressContainerBodyLabel}
`;

export const TargetPressTablePressId = styled.div`
  max-width: 14.4rem;
  ${CommonStyle}

  span {
    ${PressContainerBodyLabel}
    padding: var(--Spacing-2, 0.2rem) var(--Spacing-6, 0.6rem);
  }
`;

export const TargetPressTablePressDateData = styled.div`
  max-width: 8rem;
  ${CommonStyle}

  div {
    max-width: 8rem;
    ${PressContainerBodyLabel}
    color: #e53e06;

    div {
      ${PressContainerBodyLabel}
      padding: var(--Spacing-2, 0.2rem) var(--Spacing-6, 0.6rem);
      color: #e53e06;
    }
  }
`;

export const TargetPressTablePressDate = styled.div`
  max-width: 8rem;
  ${CommonStyle}

  span {
    ${PressContainerBodyLabel}
    padding: var(--Spacing-8, 0.8rem) var(--Spacing-4, 0.4rem);
    color: #e53e06;
  }
`;

export const TargetPressTableSeller = styled.div`
  max-width: 12.5rem;
  ${CommonStyle}
`;

export const TargetPressTableSellerData = styled.div`
  max-width: 12.5rem;
  ${CommonStyle}

  div {
    ${PressContainerBodyLabel}

    div {
      ${PressContainerBodyLabel}
      padding: var(--Spacing-2, 0.2rem) var(--Spacing-6, 0.6rem);
      color: #0198e9;
    }
  }
`;

export const TargetPressTableBuyer = styled.div`
  max-width: 12.5rem;
  ${CommonStyle}
`;

export const TargetPressTableBuyerData = styled.div`
  max-width: 12.5rem;
  ${CommonStyle}

  div {
    ${PressContainerBodyLabel}

    div {
      ${PressContainerBodyLabel}
      padding: var(--Spacing-2, 0.2rem) var(--Spacing-6, 0.6rem);
      color: #0198e9;
    }
  }
`;

export const TargetPressTableDealType = styled.div`
  max-width: 10rem;
  ${CommonStyle}
`;

export const TargetPressTableDealTypeData = styled.div`
  max-width: 10rem;
  ${CommonStyle}

  div {
    ${PressContainerBodyLabel}

    div {
      ${PressContainerBodyLabel}
      padding: var(--Spacing-2, 0.2rem) var(--Spacing-6, 0.6rem);
      color: #0198e9;
    }
  }
`;

export const TargetPressTablePressTitle = styled.div`
  ${CommonStyle}
`;

export const TargetPressTablePressTitleData = styled.div`
  ${CommonStyle}
`;

export const TargetPressTablePressTitleOverflow = styled.div`
  ${PressContainerBodyLabel}
  padding: var(--Spacing-2, 0.2rem) var(--Spacing-6, 0.6rem);
`;
