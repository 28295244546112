import React, { SVGAttributes } from "react";

import * as S from "./ModalityIcon.style";
import {
  ModalityBackgroundType,
  ModalityType,
} from "./types/modalityType.types";
import {
  IconAntibody,
  IconCellTherapy,
  IconDefault,
  IconExosome,
  IconGeneTherapy,
  IconImagingAgent,
  IconMacroMolecule,
  IconMicroBiome,
  IconRadiopharmaceutical,
  IconSmallMolecule,
  IconTherapeuticProtein,
  IconVaccine,
  IconBloodDerivatives,
} from "../../Icons";
import { AvatarBackground } from "../core";

interface Props extends SVGAttributes<SVGAElement> {
  modalityType: ModalityType;
  backgroundType: ModalityBackgroundType;
  backgroundColor?: string;
  width?: number;
  height?: number;
  iconColor?: string;
}

const ModalityIcon = ({
  modalityType,
  backgroundType = "default",
  backgroundColor,
  width = 24,
  height = 24,
  iconColor = "#515C6C",
  ...rest
}: Props) => {
  const modalityIcon = {
    antibody: IconAntibody,
    cellTherapy: IconCellTherapy,
    exosome: IconExosome,
    geneTherapy: IconGeneTherapy,
    imagingAgent: IconImagingAgent,
    macromolecule: IconMacroMolecule,
    microBiome: IconMicroBiome,
    radiopharmaceutical: IconRadiopharmaceutical,
    smallMolecule: IconSmallMolecule,
    therapeuticProtein: IconTherapeuticProtein,
    vaccine: IconVaccine,
    bloodDerivatives: IconBloodDerivatives,
    biologicallyDerivedExtracts: IconBloodDerivatives,
    functionBased: IconDefault,
    empty: IconDefault,
  };

  const CurrentIcon = modalityIcon[modalityType];

  if (backgroundType === "transparent") {
    return (
      <CurrentIcon width={width} height={height} color={iconColor} {...rest} />
    );
  }

  return (
    <S.ModalityIconWrapper>
      <S.IconBackground
        $backgroundType={backgroundType}
        $backgroundColor={backgroundColor}
      >
        <AvatarBackground
          containerSize={`${width / 10}rem`}
          className="modality-icon-background"
        >
          <CurrentIcon
            width={width}
            height={height}
            color={iconColor}
            {...rest}
          />
        </AvatarBackground>
      </S.IconBackground>
    </S.ModalityIconWrapper>
  );
};

export default ModalityIcon;
