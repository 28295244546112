import {
  useArticleInfoMutation,
  useArticleInfoQuery,
} from "@api/articleInfo/useArticleInfo";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import { useModalityCategoryUpdate } from "@FillInTableContainer/components/FillInTable/hooks/useModalityCategoryUpdate";
import { useTableCreatorFormatErrorPressId } from "@FillInTableContainer/shared/context/TableCreatorFormatErrorPressIdContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import { useCreateMainLabel } from "@hooks/useCreateMainLabel";
import { useGetEntryData } from "@hooks/useGetEntryData";
import { useMovePage } from "@hooks/useMovePage";
import { useResetArticleContent } from "@hooks/useResetArticleContent";
import { useUndoRedo } from "@hooks/useUndoRedo";
import { useUpdateEntryData } from "@hooks/useUpdateArticleData";
import IconArrowLeftDisable from "@images/common/icon-arrow-left-gray.svg";
import IconArrowLeft from "@images/common/icon-arrow-left.svg";
import IconStrokeCheck from "@images/common/icon-stroke-check.svg";
import IconSavedToStoreFullDisable from "@images/fillInTable/tableCreator/icon-saved-to-store-full-gray.svg";
import IconSavedToStoreHalf from "@images/fillInTable/tableCreator/icon-saved-to-store-half.svg";
import { useFillInTableRoles } from "@libs/roles/hooks/useFillInTableRoles";
import PublishablePressCheckbox from "@organisms/PublishablePressCheckbox/PublishablePressCheckbox";
import { useEntryDataValue } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import * as S from "./ClinicalTrialStatusBottomButtonContainer.style";
import { useCheckValidationInClinicalTrialStatusTableCreator } from "./hooks/useCheckValidationInClinicalTrialStatusTableCreator";
import FillInTableContentsFabContainer from "../../../FillInTableContentsFabContainer/FillInTableContentsFabContainer";

const ClinicalTrialStatusBottomButtonContainer = () => {
  const draftArticleId = getPressIdFromURL();
  const entryData = useEntryDataValue(draftArticleId);
  const getEntryData = useGetEntryData();
  const resetArticleContent = useResetArticleContent();
  const { createMainLabel } = useCreateMainLabel();

  // NOTE : DataFormat으로 에러가 발생한 경우, Bottom Button을 비활성화 시키기 위한 로직
  const { errorPressId } = useTableCreatorFormatErrorPressId();
  const isDisabledPress = errorPressId === draftArticleId;

  const { data: articleInfo } = useArticleInfoQuery(Number(draftArticleId));
  const { mutateAsync: updateArticleInfo } = useArticleInfoMutation(
    Number(draftArticleId),
  );
  const { updateModalityCategoryValue } = useModalityCategoryUpdate();
  const updateEntryData = useUpdateEntryData();
  const status = articleInfo?.draftArticleStatus;

  const movePage = useMovePage();

  const { handleCheckValidationHalf } =
    useCheckValidationInClinicalTrialStatusTableCreator();

  const { currentHistory } = useTableCreatorCurrentHistory();

  const { undo, redo, isDisabledRedo, isDisabledUndo, resetHistory } =
    useUndoRedo({
      state: currentHistory,
    });

  const undoRedoEvents = {
    undo,
    redo,
    isDisabledRedo,
    isDisabledUndo,
  };

  const { hasHalfButtonRoles, hasPrevPageAccessRoles, isSuperAdmin } =
    useFillInTableRoles();

  const moveNext = () => {
    movePage("pressList");
  };

  const movePrev = async () => {
    await getEntryData();
    movePage("pressClassification");
  };

  const saveToDBHalf = async () => {
    const ftEditorName = articleInfo?.ftEditorName;

    await updateEntryData(entryData, true);

    if (ftEditorName === null || ftEditorName === "") {
      await updateArticleInfo({
        draftArticleStatus: DRAFT_STATUS_CODE.dbSavedHalf,
        type: "FT",
        isCheckStartDate: "true",
        design1Hidden: true,
      });
    } else {
      await updateArticleInfo({
        draftArticleStatus: DRAFT_STATUS_CODE.dbSavedHalf,
        design1Hidden: true,
      });
    }
    updateModalityCategoryValue();
    await resetArticleContent();
    await createMainLabel();
    resetHistory();
  };

  const reviewInProgressHalf = () => {
    updateArticleInfo({
      draftArticleStatus: DRAFT_STATUS_CODE.reviewInProgressHalf,
      design1Hidden: true,
    });
  };

  const handleClickSaveToDBHalf = () => {
    if (handleCheckValidationHalf()) {
      saveToDBHalf();
    }
  };

  const isVisibleHalfSaveToDB =
    status === DRAFT_STATUS_CODE.reviewInProgressHalf ||
    status === DRAFT_STATUS_CODE.aiEntryDone;

  const isPressClassificationButtonDisabled = !hasPrevPageAccessRoles;

  // SuperAdmin인 경우에는 Fill In Table이 완료되면 언제든 버튼이 활성화 되어야 한다.
  const isAbleSuperAdminButton =
    (status === DRAFT_STATUS_CODE.dbSaved ||
      status === DRAFT_STATUS_CODE.insertImage ||
      status === DRAFT_STATUS_CODE.editArticle ||
      status === DRAFT_STATUS_CODE.published) &&
    isSuperAdmin;

  const isHalfButtonDisabled =
    (status === undefined ||
      (status !== DRAFT_STATUS_CODE.aiEntryDone &&
        status !== DRAFT_STATUS_CODE.reviewInProgressHalf &&
        status !== DRAFT_STATUS_CODE.dbSavedHalf) ||
      !hasHalfButtonRoles) &&
    !isAbleSuperAdminButton;

  const isDoneButtonDisabled =
    status === undefined ||
    status === DRAFT_STATUS_CODE.aiEntryDone ||
    status === DRAFT_STATUS_CODE.reviewInProgressHalf;

  return (
    <>
      <S.LeftButtonWrapper>
        <S.PrevButton
          onClick={movePrev}
          disabled={isPressClassificationButtonDisabled}
        >
          <img
            src={
              isPressClassificationButtonDisabled
                ? IconArrowLeftDisable
                : IconArrowLeft
            }
            alt="go to press class"
          />
          Press Class
        </S.PrevButton>
        <S.PublishableButtonWrapper>
          <PublishablePressCheckbox />
        </S.PublishableButtonWrapper>
      </S.LeftButtonWrapper>
      <S.RightButtonWrapper>
        <FillInTableContentsFabContainer undoRedoEvents={undoRedoEvents} />

        {isVisibleHalfSaveToDB ? (
          <S.NextButton
            onClick={handleClickSaveToDBHalf}
            disabled={isHalfButtonDisabled || isDisabledPress}
          >
            Save to DB (Half)
            <img
              src={
                isHalfButtonDisabled || isDisabledPress
                  ? IconSavedToStoreFullDisable
                  : IconSavedToStoreHalf
              }
              alt="store to DB"
            />
          </S.NextButton>
        ) : (
          <S.ReviewInProgressButton
            onClick={reviewInProgressHalf}
            disabled={isHalfButtonDisabled || isDisabledPress}
          >
            Review in Progress (Half)
            <img src={IconStrokeCheck} alt="Review in Progress" />
          </S.ReviewInProgressButton>
        )}

        <S.NextButton onClick={moveNext} disabled={isDoneButtonDisabled}>
          Done
        </S.NextButton>
      </S.RightButtonWrapper>
    </>
  );
};

export default ClinicalTrialStatusBottomButtonContainer;
