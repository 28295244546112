import React, { HTMLAttributes } from "react";

import { IconCheck } from "@frontend/common";

import * as S from "./OverlappedCheckBox.style";

interface Props extends HTMLAttributes<HTMLInputElement> {
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: number;
  checked: boolean;
}

export const Checkbox = ({ onChangeHandler, value, checked }: Props) => {
  return (
    <S.CheckboxWrapper>
      <S.Checkbox
        type="checkbox"
        onChange={onChangeHandler}
        checked={checked}
        value={value}
      />
      <S.CheckIcon $isSelected={checked ?? false}>
        <IconCheck width={16} height={16} color="white" />
      </S.CheckIcon>
    </S.CheckboxWrapper>
  );
};

export default Checkbox;
