export type Section =
  | "clinicalTrials"
  | "clinicalTrialStatus"
  | "Deals"
  | "Regulatory"
  | "expeditedProgram"
  | "ventureCapital";

export type SectionCode =
  | "TY001"
  | "TY002"
  | "TY003"
  | "TY004"
  | "TY005"
  | "TY006";

export const SECTION_ACCORDING_TO_CLASSIFICATION: Record<string, Section> = {
  C000: "clinicalTrials",
  C001: "clinicalTrialStatus",
  C003: "clinicalTrials",
  C004: "clinicalTrials",
  C005: "clinicalTrials",
  C006: "Deals",
  C007: "clinicalTrials",
  C008: "clinicalTrials",
  C009: "clinicalTrials",
  C010: "clinicalTrials",
  C011: "clinicalTrials",
  C012: "clinicalTrials",
  C013: "Regulatory",
  C014: "clinicalTrials",
  C015: "clinicalTrials",
  C016: "expeditedProgram",
  C017: "clinicalTrials",
  C018: "clinicalTrials",
  C019: "clinicalTrials",
  C020: "clinicalTrials",
  C021: "ventureCapital",
  C022: "clinicalTrials",
  C023: "clinicalTrials",
};

export const SECTION_CODE: Record<Section, SectionCode> = {
  clinicalTrials: "TY001",
  clinicalTrialStatus: "TY005",
  Deals: "TY002",
  Regulatory: "TY003",
  expeditedProgram: "TY004",
  ventureCapital: "TY006",
};
