import styled, { css } from "styled-components";

export const SearchWrapper = styled.div<{
  inputWidth?: number;
  widthResize?: boolean;
}>`
  position: relative;
  display: flex;
  width: ${({ inputWidth }) => inputWidth}rem;
  border-radius: 0.4rem;
  border: 1px solid ${({ theme }) => theme.color.neural.gray05};
  margin-left: 0.8rem;
  overflow: hidden;

  ${({ widthResize }) =>
    widthResize &&
    css`
      width: 100%;
      min-width: 43.8rem;
    `}
  &:has(div:focus) {
    border: 1px solid ${({ theme }) => theme.color.node.sky};
  }
`;

export const SearchInput = styled.div<{
  placeholder?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  height: 2.4rem;
  color: ${({ theme }) => theme.color.neural.gray10};
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 130%;
  padding-left: 0.4rem;
  margin: 0;
  margin-right: 1rem;
  outline: none;
  border-radius: 0.4rem;
  cursor: text;

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  // contenteditable의 placeholder를 표시하기 위한 가상요소
  &:empty:before {
    color: ${({ theme }) => theme.color.neural.gray04};
    content: attr(placeholder);
  }

  &[contenteditable] {
    -webkit-user-modify: read-write-plaintext-only;
    -moz-user-modify: read-write-plaintext-only;
    -ms-user-modify: read-write-plaintext-only;
  }
`;

export const HiddenTextBox = styled.span`
  visibility: hidden;
  height: 0;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 130%;
  padding-left: 0.4rem;
  margin: 0;
  position: absolute;
`;

export const SEARCH_TYPING_HINT_WIDTH = 6.6;
export const SearchTypingHintWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${SEARCH_TYPING_HINT_WIDTH}rem;
`;

export const SearchTypingHint = styled.span`
  white-space: nowrap;
  color: ${({ theme }) => theme.color.neural.gray04};
  font-size: 1rem;
  font-weight: 500;
  line-height: 130%;
  margin-left: 0.4rem;
`;

export const SEARCH_BUTTON_WIDTH = 2.4;
export const SearchButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${SEARCH_BUTTON_WIDTH}rem;
  height: 2.4rem;
  cursor: pointer;
`;

export const DeleteButton = styled.button`
  position: absolute;
  right: 0.8rem;
  top: 0.6rem;
  width: 1.2rem;
  height: 1.2rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
  }
`;
