import React, { useCallback, useEffect, useState } from "react";

export interface UseDragModalReturn {
  draggedTargetRef: React.RefObject<HTMLDivElement>;
  draggedPosition: { x: number; y: number };
  onMouseDown: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export const useDragModal = () => {
  const draggedTargetRef = React.useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [mouseDownPosition, setMouseDownPosition] = useState({ x: 0, y: 0 });
  const [draggedPosition, setDraggedPosition] = useState({ x: 0, y: 0 });

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsDragging(true);
    const { left, top } = e.currentTarget.getBoundingClientRect();
    setMouseDownPosition({
      x: e.clientX - left,
      y: e.clientY - top,
    });
  };

  const onMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!isDragging) {
        document.body.style.cursor = "default";
        return;
      }
      setDraggedPosition({
        x: e.clientX - mouseDownPosition.x,
        y: e.clientY - mouseDownPosition.y,
      });
      document.body.style.cursor = "grab";
    },
    [isDragging, mouseDownPosition.x, mouseDownPosition.y],
  );

  const onMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const modalWidth = draggedTargetRef.current?.offsetWidth || 0;
    const modalHeight = draggedTargetRef.current?.offsetHeight || 0;

    const initialX = (windowWidth - modalWidth) / 2;
    const initialY = (windowHeight - modalHeight) / 2;

    setDraggedPosition({ x: initialX, y: initialY });
  }, []);

  useEffect(() => {
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
      document.body.style.cursor = "default";
    };
  }, [onMouseMove]);

  return {
    draggedTargetRef,
    draggedPosition,
    onMouseDown,
    setDraggedPosition,
  };
};
