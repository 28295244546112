import { useState, useEffect } from "react";

import { SimilarDataResponse } from "@api/dealsSimilarData/dealsSimilarData.type";

import * as S from "./SimilarDataButtonContainer.style";
import SimilarDataModalContainer from "../SimilarDataModalContainer/SimilarDataModalContainer";

type Props = {
  openCheckModal: () => void;
  closeCheckModal: () => void;
  isCheckModalOpen: boolean;
  data: SimilarDataResponse;
};

const SimilarDataButtonContainer = ({
  openCheckModal,
  isCheckModalOpen,
  closeCheckModal,
  data,
}: Props) => {
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  useEffect(() => {
    openCheckModal();
    // NOTE: 처음 렌더링 될 때(중복 기사가 있어서 열어줄 때) 모달 열리게하기 위해 빈배열 넣어준 것
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.SimilarDataWrapper>
      <S.DividedLine />
      <S.SimilarDataButton
        onClick={(e) => {
          openCheckModal();
          setIsButtonClicked((prev) => !prev);
          e.stopPropagation();
        }}
      >
        Similar Data
      </S.SimilarDataButton>

      {isCheckModalOpen && (
        <SimilarDataModalContainer
          closeCheckModal={closeCheckModal}
          data={data}
          isButtonClicked={isButtonClicked}
        />
      )}
    </S.SimilarDataWrapper>
  );
};

export default SimilarDataButtonContainer;
