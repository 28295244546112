import styled, { css } from "styled-components";

export const ToggleButtonWrapper = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  gap: 0.8rem;
`;

export const ToggleCheckbox = styled.input`
  width: 4rem;
  height: 2rem;
  visibility: hidden;
  margin: 0;
  &:checked + label:not([disabled]) {
    background: var(--Color-Common-Bg-Interactive-Primary, #1baefe);

    border: 1px solid var(--Color-Common-Bg-Interactive-Primary, #1baefe);

    &:hover {
      background: var(--Color-Common-Bg-Interactive-Primary-Hovered, #0198e9);
      border: 1px solid
        var(--Color-Common-Bg-Interactive-Primary-Hovered, #0198e9);
    }

    &:active {
      background: var(--Color-Common-Bg-Interactive-Primary-Pressed, #017abc);
      border: 1px solid
        var(--Color-Common-Bg-Interactive-Primary-Pressed, #017abc);
    }

    &:before {
      background: var(--Color-Common-Bg-Primary, #fff);

      transform: translateX(2rem);
    }
  }
`;

export const ToggleLabel = styled.label<{ disabled?: boolean }>`
  position: absolute;
  display: flex;
  top: 0.1rem;
  width: 4rem;
  height: 2rem;
  border-radius: 1.6rem;
  border: 1px solid var(--Color-Common-Border-Interactive-Secondary, #b1b8c4);
  background: var(--Color-Common-Bg-Secondary, #f0f2f4);
  transition: transform 0.4s ease-in-out;
  transform-origin: 50% 50%;

  &:hover:not([disabled]) {
    background: var(--Color-Common-Bg-Tertiary, #cbd0d8);
  }

  &:active:not([disabled]) {
    border: 1px solid
      var(--Color-Common-Border-Interactive-Secondary-Pressed, #7c899c);
  }

  ${({ disabled }) =>
    disabled
      ? css`
          cursor: not-allowed;
        `
      : css`
          cursor: pointer;
        `}

  &:before {
    position: absolute;
    top: 0.1rem;
    left: 0.1rem;
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: var(--Border-Radius-circle, 99999.9rem);
    background: var(--Color-Common-Bg-Primary, #fff);

    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
      0px 0px 2px 0px rgba(0, 0, 0, 0.12);

    border-radius: 50%;
    transition: transform 0.4s ease;
    content: "";

    ${({ disabled }) =>
      disabled &&
      css`
        background: var(--Color-Common-Bg-Disabled, #cbd0d8);
        box-shadow: none;
      `}
  }
`;

export const ToggleText = styled.span<{
  $textColor?: string;
  $disabled?: boolean;
}>`
  font-size: 1.4rem;
  font-weight: 500;
  color: ${({ $textColor }) =>
    $textColor || "var(--Color-Common-Text-Primary, #2a2f37)"};
  line-height: 130%;
  cursor: pointer;

  ${({ $disabled }) =>
    $disabled &&
    css`
      color: var(--Color-Common-Text-Disabled, #96a1b0);
      cursor: not-allowed;
    `}
`;
