import { useEffect } from "react";

import { showToast } from "@atoms/Toast/Toast";

type Props = {
  message: string;
  clickButton?: () => void;
};

const ToastMessageContainer = ({ message, clickButton }: Props) => {
  useEffect(() => {
    showToast({
      icon: "error",
      backgroundColor: "hotPink",
      text: message,
      position: "bottomLeft",
      autoClose: 5000,
      customButton: clickButton && [
        {
          name: "Retry",
          action: clickButton,
        },
      ],
    });
  }, [clickButton, message]);

  return <></>;
};

export default ToastMessageContainer;
