import { ModalityType } from "../types/modalityType.types";

export const MODALITY_KEY_FROM_CATEGORY: Record<string, ModalityType> = {
  antibody: "antibody",
  "cell therapy": "cellTherapy",
  exosome: "exosome",
  "gene therapy": "geneTherapy",
  "imaging agent": "imagingAgent",
  macromolecule: "macromolecule",
  microbiome: "microBiome",
  radiopharmaceutical: "radiopharmaceutical",
  "small molecule": "smallMolecule",
  "therapeutic protein": "therapeuticProtein",
  vaccine: "vaccine",
  "function based": "functionBased",
  "blood derivatives": "bloodDerivatives",
  "biologically derived extracts": "biologicallyDerivedExtracts",
  empty: "empty",
};
