import { useMutation } from "@tanstack/react-query";

import { queryClient } from "@api/queryClient";
import { QUERY_KEYS } from "@api/queryKeys";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import { getPressIdFromURL } from "@utils/getStateFromURL";
import { toastFail } from "@utils/toast";

import { dealsOverlappedUpdateApis } from "./dealsOverlappedUpdate.service";
import { PutArticleInfoRequest } from "./dealsOverlappedUpdate.type";

const overlappedData = {
  publishable: false,
  articleRejectReason: "Overlapped press",
  draftArticleStatus: `${DRAFT_STATUS_CODE.unsuitable}`,
};

export const useDealsOverlappedUpdateMutation = (
  draftArticleId = Number(getPressIdFromURL()),
) => {
  const mutate = useMutation({
    mutationKey: [QUERY_KEYS.dealsOverlappedUpdate, getPressIdFromURL()],
    mutationFn: async ({
      targetId,
      similarId,
      isMcEditor,
    }: {
      targetId: number;
      similarId: number;
      isMcEditor: boolean;
    }) => {
      const defaultPressStatus = {
        ...overlappedData,
        draftArticleId: targetId,
        duplArticleId: similarId,
      };

      const mcEditorStatus = {
        ...overlappedData,
        draftArticleId: targetId,
        duplArticleId: similarId,
        type: "MC",
        isCheckStartDate: "true",
      } as PutArticleInfoRequest;

      const payload = isMcEditor ? defaultPressStatus : mcEditorStatus;

      const response = await dealsOverlappedUpdateApis.put(payload);
      return response;
    },
    onSuccess: async (response) => {
      if (response.code !== 200) {
        toastFail(response.message);
        throw new Error(response.message);
      }
      setTimeout(() => {
        queryClient.refetchQueries({
          queryKey: [QUERY_KEYS.articleInfo, Number(draftArticleId)],
        });
      }, 100);
    },
  });
  return mutate;
};
