import { useCreateMainLabelMutation } from "@api/mainLabel/mainLabel";
import { getPressIdFromURL } from "@utils/getStateFromURL";

/**
 * NOTE : DB Saved 시 MainLabel 생성 API 호출
 */
export const useCreateMainLabel = () => {
  const { mutateAsync: createMainLabel } = useCreateMainLabelMutation();
  const draftArticleIds = [getPressIdFromURL()];

  return {
    createMainLabel: async () => {
      const response = await createMainLabel(draftArticleIds);
      return response;
    },
  };
};
