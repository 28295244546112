import React, { useRef, useState } from "react";

import {
  Alignment,
  Position,
} from "../../../../../shared/components/core/Tooltip/types/tooltip.types";

type Returns = {
  changeTooltipPosition: () => void;
  resetTooltipPosition: () => void;
  showTooltip: boolean;
  tooltipPosition: { position: Position; alignment: Alignment };
  tooltipRef: React.RefObject<HTMLDivElement>;
  touchToChangeTooltipStatus: () => void;
};

export const useChangeTooltipStatus = (): Returns => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [tooltipPosition, setTooltipPosition] = useState<{
    position: Position;
    alignment: Alignment;
  }>({ position: "top", alignment: "center" });
  const [showTooltip, setShowTooltip] = useState(false);

  const changeTooltipPosition = () => {
    if (!tooltipRef.current) return;

    const tooltipRect = tooltipRef.current.getBoundingClientRect();

    const sliderContainer = document
      .getElementById("article-slide-container")
      ?.getBoundingClientRect();

    const left = tooltipRect?.left || 0;
    const right = tooltipRect?.right || 0;
    const top = tooltipRect?.top || 0;
    const bottom = tooltipRect?.bottom || 0;

    const sliderContainerWidth = sliderContainer?.width || 0;
    const sliderContainerLeft = sliderContainer?.left || 0;

    const isHomePath = window.location.pathname === "/";

    const windowWidth = window.innerWidth;

    const standardRightWidth =
      windowWidth < 990 || !isHomePath ? windowWidth : sliderContainerWidth;

    const standardLeftWidth = windowWidth < 990 ? 0 : sliderContainerLeft;

    // NOTE: 30px 추가로 위치 조정
    if (right + 30 > standardRightWidth)
      setTooltipPosition((prev) => ({ ...prev, alignment: "right" }));
    if (left < standardLeftWidth)
      setTooltipPosition((prev) => ({ ...prev, alignment: "left" }));
    // NOTE: 헤더 높이 포함하여 계산
    if (top < 70) setTooltipPosition((prev) => ({ ...prev, position: "top" }));

    if (bottom > window.innerHeight && !isHomePath)
      setTooltipPosition((prev) => ({ ...prev, position: "bottom" }));

    setShowTooltip(true);
  };

  const resetTooltipPosition = () => {
    setShowTooltip(false);
    setTooltipPosition({ position: "top", alignment: "center" });
  };

  const touchToChangeTooltipStatus = () => {
    if (!showTooltip) return changeTooltipPosition();

    return resetTooltipPosition();
  };

  return {
    changeTooltipPosition,
    resetTooltipPosition,
    touchToChangeTooltipStatus,
    showTooltip,
    tooltipPosition,
    tooltipRef,
  };
};
