import React from "react";

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const IconDrag = ({ width = 24, height = 24, color = "black" }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <g opacity="0.5">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.4419 4.44194C12.686 4.19786 12.686 3.80214 12.4419 3.55806C12.1979 3.31398 11.8021 3.31398 11.5581 3.55806L3.55806 11.5581C3.31398 11.8021 3.31398 12.1979 3.55806 12.4419C3.80214 12.686 4.19786 12.686 4.44194 12.4419L12.4419 4.44194ZM12.4419 8.44194C12.686 8.19786 12.686 7.80214 12.4419 7.55806C12.1979 7.31398 11.8021 7.31398 11.5581 7.55806L7.55806 11.5581C7.31398 11.8021 7.31398 12.1979 7.55806 12.4419C7.80214 12.686 8.19786 12.686 8.44194 12.4419L12.4419 8.44194Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

IconDrag.displayName = "Drag";

export default IconDrag;
