import type { ClassificationCode } from "@/types/code.types";

interface ClassificationGroup {
  [key: string]: string;
}

type EmptyCode = "";
export interface ClassificationList {
  code: ClassificationCode | EmptyCode;
  name: string;
  isCheck: boolean;
}

export const CLASSIFICATION_GROUP: ClassificationGroup = {
  C000: "clinical, preclinical, R&D",
  C005: "conference, poster contents",
  C006: "deal, cooperation",
  C021: "venture capital",
  C013: "approval process",
  C016: "expedited program",
  C001: "clinical trial status",
  C003: "manufacture",
  C004: "conference schedule",
  C007: "human resource",
  C008: "publication, journal",
  C009: "business, financial update",
  C010: "patent, IP, lawsuit",
  C011: "shareholder, board",
  C012: "FI interaction, other financial news",
  C014: "stock option",
  C015: "regulatory meeting, committee, guideline",
  C017: "reimbursement, coverage, PBM",
  C018: "launch",
  C019: "product performance",
  C022: "IPO, Listing",
  C023: "interview",
  C020: "etc",
};

export const CLASSIFICATION_CODE_FROM_NAME = {
  "clinical, preclinical, R&D": "C000",
  "clinical trial status": "C001",
  manufacture: "C003",
  "conference schedule": "C004",
  "conference, poster contents": "C005",
  "deal, cooperation": "C006",
  "human resource": "C007",
  "publication, journal": "C008",
  "business, financial update": "C009",
  "patent, IP, lawsuit": "C010",
  "shareholder, board": "C011",
  "FI interaction, other financial news": "C012",
  "approval process": "C013",
  "stock option": "C014",
  "regulatory meeting, committee, guideline": "C015",
  "expedited program": "C016",
  "reimbursement, coverage, PBM": "C017",
  launch: "C018",
  "product performance": "C019",
  etc: "C020",
  "venture capital": "C021",
  "IPO, Listing": "C022",
  interview: "C023",
} as const;

export const CLASSIFICATION_IS_PUBLISHED: ClassificationGroup = {
  C000: "Publish",
  C001: "DB Save",
  C003: "DB Save",
  C004: "DB Save",
  C005: "Publish",
  C006: "Publish",
  C007: "DB Save",
  C008: "DB Save",
  C009: "DB Save",
  C010: "DB Save",
  C011: "DB Save",
  C012: "DB Save",
  C013: "Publish",
  C014: "DB Save",
  C015: "Publish",
  C016: "Publish",
  C017: "DB Save",
  C018: "DB Save",
  C019: "DB Save",
  C020: "DB Save",
  C021: "DB Save",
  C022: "DB Save",
  C023: "DB Save",
};

export const MANUAL_CLASSIFICATION_LIST: ClassificationList[] = [
  {
    code: "C000",
    name: "clinical, preclinical, R&D",
    isCheck: false,
  },
  {
    code: "C005",
    name: "conference, poster contents",
    isCheck: false,
  },
  {
    code: "C006",
    name: "deal, cooperation",
    isCheck: false,
  },
  {
    code: "C021",
    name: "venture capital",
    isCheck: false,
  },
  {
    code: "C013",
    name: "approval process",
    isCheck: false,
  },
  {
    code: "C016",
    name: "expedited program",
    isCheck: false,
  },
  {
    code: "C001",
    name: "clinical trial status",
    isCheck: false,
  },
  {
    code: "C003",
    name: "manufacture",
    isCheck: false,
  },
  {
    code: "C004",
    name: "conference schedule",
    isCheck: false,
  },
  {
    code: "C007",
    name: "human resource",
    isCheck: false,
  },
  {
    code: "C008",
    name: "publication, journal",
    isCheck: false,
  },
  {
    code: "C009",
    name: "business, financial update",
    isCheck: false,
  },
  {
    code: "C010",
    name: "patent, IP, lawsuit",
    isCheck: false,
  },
  {
    code: "C011",
    name: "shareholder, board",
    isCheck: false,
  },
  {
    code: "C012",
    name: "FI interaction, other financial news",
    isCheck: false,
  },
  {
    code: "C014",
    name: "stock option",
    isCheck: false,
  },
  {
    code: "C015",
    name: "regulatory meeting, committee, guideline",
    isCheck: false,
  },
  {
    code: "C017",
    name: "reimbursement, coverage, PBM",
    isCheck: false,
  },
  {
    code: "C018",
    name: "launch",
    isCheck: false,
  },
  {
    code: "C019",
    name: "product performance",
    isCheck: false,
  },
  {
    code: "C022",
    name: "IPO, Listing",
    isCheck: false,
  },
  {
    code: "C023",
    name: "interview",
    isCheck: false,
  },
  {
    code: "C020",
    name: "etc",
    isCheck: false,
  },
  {
    code: "",
    name: "none",
    isCheck: false,
  },
];

export const AI_CLASSIFICATION_LIST: ClassificationList[] = [
  {
    code: "C000",
    name: "clinical, preclinical, R&D",
    isCheck: false,
  },
  {
    code: "C005",
    name: "conference, poster contents",
    isCheck: false,
  },
  {
    code: "C006",
    name: "deal, cooperation",
    isCheck: false,
  },
  {
    code: "C021",
    name: "venture capital",
    isCheck: false,
  },
  {
    code: "C013",
    name: "approval process",
    isCheck: false,
  },
  {
    code: "C016",
    name: "expedited program",
    isCheck: false,
  },
  {
    code: "C001",
    name: "clinical trial status",
    isCheck: false,
  },
  {
    code: "C003",
    name: "manufacture",
    isCheck: false,
  },
  {
    code: "C004",
    name: "conference schedule",
    isCheck: false,
  },
  {
    code: "C007",
    name: "human resource",
    isCheck: false,
  },
  {
    code: "C008",
    name: "publication, journal",
    isCheck: false,
  },
  {
    code: "C009",
    name: "business, financial update",
    isCheck: false,
  },
  {
    code: "C010",
    name: "patent, IP, lawsuit",
    isCheck: false,
  },
  {
    code: "C011",
    name: "shareholder, board",
    isCheck: false,
  },
  {
    code: "C012",
    name: "FI interaction, other financial news",
    isCheck: false,
  },
  {
    code: "C014",
    name: "stock option",
    isCheck: false,
  },
  {
    code: "C015",
    name: "regulatory meeting, committee, guideline",
    isCheck: false,
  },
  {
    code: "C017",
    name: "reimbursement, coverage, PBM",
    isCheck: false,
  },
  {
    code: "C018",
    name: "launch",
    isCheck: false,
  },
  {
    code: "C019",
    name: "product performance",
    isCheck: false,
  },
  {
    code: "C022",
    name: "IPO, Listing",
    isCheck: false,
  },
  {
    code: "C023",
    name: "interview",
    isCheck: false,
  },
  {
    code: "C020",
    name: "etc",
    isCheck: false,
  },
];
