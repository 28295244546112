import styled from "styled-components";

import {
  DefaultLeftButtonWrapper,
  DefaultRightButtonWrapper,
  DefaultBottomButton,
  DefaultPrevButton,
  DefaultNextButton,
  DefaultReviewInProgressButton,
} from "@FillInTableContainer/shared/styles/bottomStatusButton.style";

export const LeftButtonWrapper = styled(DefaultLeftButtonWrapper)``;

export const PublishableButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RightButtonWrapper = styled(DefaultRightButtonWrapper)``;

export const BottomButton = styled(DefaultBottomButton)``;

export const PrevButton = styled(DefaultPrevButton)``;

export const NextButton = styled(DefaultNextButton)``;

export const ReviewInProgressButton = styled(DefaultReviewInProgressButton)``;
