"use client";

import { styled } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  gap: 0.4rem;
`;

export const SecondColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
  height: 100%;
  gap: 0.4rem;
`;

export const ThirdColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 17%;
  height: 100%;
  gap: 0.4rem;
`;

export const HalfColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  gap: 0.4rem;
`;

export const HalfRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  height: 100%;
  gap: 0.4rem;
`;

export const GroupWrapper = styled.div<{ $width: string }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: var(--Border-Radius-8, 0.8rem);
  background: #fff;
  width: ${({ $width }) => $width};
`;

export const GroupTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
  width: 100%;
  padding-bottom: 0.4rem;
`;

export const GroupContentsWrapper = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
`;
