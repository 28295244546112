import { styled, css } from "styled-components";

export const PressContainerHeaderLabel = css`
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-family: var(--font-pt-serif);
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.43rem;
  display: flex;
  padding: var(--Spacing-2, 0.2rem) var(--Spacing-6, 0.6rem);
  align-items: flex-start;
  gap: var(--Spacing-2, 0.2rem);
  flex: 1 0 0;
`;

export const PressContainerBodyLabel = css`
  font-family: var(--font-pt-serif);
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.56rem;
`;

export const CommonStyle = css`
  display: flex;
  flex: 1 0 0;
  padding: var(--Spacing-8, 0.8rem) var(--Spacing-4, 0.4rem);
  align-items: flex-start;
  gap: var(--Spacing-2, 0.2rem);
  border-right: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
`;

export const SimilarPressTableContainer = styled.div<{ isResizing: boolean }>`
  display: flex;
  position: relative;
  height: ${({ isResizing }) => (isResizing ? "100%" : "fit-content")};
  max-height: ${({ isResizing }) => (isResizing ? "none" : "60rem")};
  overflow-y: auto;
  padding: var(--Spacing-8, 0.8rem);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Spacing-8, 0.8rem);
  align-self: stretch;
  border-radius: var(--Border-Radius-2, 0.2rem);
  background: var(--Color-Common-Bg-Primary, #fff);
  margin-top: 0.8rem;
  width: 100%;
`;

export const SimilarPressTableTitle = styled.div`
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.56rem;
`;

export const SimilarPressTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
`;

export const SimilarPressTableHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  border-right: none;
  background: var(--Color-Common-Bg-Secondary, #f0f2f4);
  height: 3.4rem;
  color: var(--Color-Common-Text-Primary, #2a2f37);
`;

export const SimilarPressTableBody = styled.div<{
  hovered: boolean;
}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  border-top: none;
  border-right: none;
  background-color: ${({ hovered }) =>
    hovered ? "#E0F4FF" : "var(--Color-Common-Bg-Interactive-Secondary, #fff)"};
`;

export const SimilarPressTableHeadDiv = styled.div`
  ${PressContainerHeaderLabel}
`;

export const SimilarPressTableBodyDiv = styled.div`
  ${PressContainerBodyLabel}
`;

export const SimilarPressTablePressId = styled.div`
  display: flex;
  max-width: 8.8rem;
  align-items: flex-start;
  ${CommonStyle}
`;

export const SimilarPressTablePressIdWrapper = styled.div`
  max-width: 8.8rem;
  align-items: flex-start;
  ${CommonStyle}
`;

export const SimilarPressId = styled.button`
  all: unset;
  ${PressContainerBodyLabel}
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  padding: var(--Spacing-2, 0.2rem) 0 var(--Spacing-2, 0.2rem)
    var(--Spacing-6, 0.6rem);
  cursor: pointer;
  align-items: center;
`;

export const SimilarPressIcon = styled.button`
  all: unset;
  cursor: pointer;
`;

export const SimilarPressTableScore = styled.div`
  max-width: 5.6rem;
  flex-direction: column;
  ${CommonStyle}

  span {
    ${PressContainerBodyLabel}
    padding: var(--Spacing-2, 0.2rem) var(--Spacing-6, 0.6rem);
  }
`;

export const SimilarPressTablePressDate = styled.div`
  max-width: 8rem;
  ${CommonStyle}
`;

export const SimilarPressTablePressDateData = styled.div`
  max-width: 8rem;
  ${CommonStyle}
`;

export const SimilarPressCheckDate = styled.div<{
  isWithinFourDays: boolean;
}>`
  ${PressContainerBodyLabel}
  padding: var(--Spacing-2, 0.2rem) var(--Spacing-6, 0.6rem);
  color: ${({ isWithinFourDays }) => (isWithinFourDays ? "#E53E06" : "")};
`;

export const SimilarPressTableSeller = styled.div`
  max-width: 12.5rem;
  ${CommonStyle}
`;

export const SimilarPressTableSellerData = styled.div`
  max-width: 12.5rem;
  ${CommonStyle}
`;

export const SimilarPressTableBuyer = styled.div`
  max-width: 12.5rem;
  ${CommonStyle}
`;

export const SimilarPressTableBuyerData = styled.div`
  max-width: 12.5rem;
  ${CommonStyle}
`;

export const SimilarPressTableDealType = styled.div`
  max-width: 10rem;
  ${CommonStyle}
`;

export const SimilarPressTableDealTypeData = styled.div`
  max-width: 10rem;
  ${CommonStyle}
`;

export const PressMatchedWrapper = styled.div<{
  matched: boolean;
}>`
  ${PressContainerBodyLabel}
  padding: var(--Spacing-2, 0.2rem) var(--Spacing-6, 0.6rem);

  color: ${({ matched }) => (matched ? "#0198E9" : "")};
`;

export const SimilarPressTablePressTitle = styled.div`
  ${CommonStyle}
  width: 100%;
`;

export const SimilarPressTablePressTitleData = styled.div`
  ${CommonStyle}
`;

export const SimilarPressTablePressTitleOverflow = styled.div`
  ${PressContainerBodyLabel}
  padding: var(--Spacing-2, 0.2rem) var(--Spacing-6, 0.6rem);
`;

export const SimilarPressTableUnsuitable = styled.div`
  max-width: 8.8rem;
  ${CommonStyle}
`;

export const SimilarPressTableUnsuitableData = styled.div`
  max-width: 8.8rem;
  ${CommonStyle}
  padding: var(--Spacing-8, 0.8rem) var(--Spacing-4, 0.4rem) var(--Spacing-8, 0.8rem) 0;

  div {
    width: 8.8rem;
    text-align: center;
    div {
      ${PressContainerBodyLabel}
      padding: var(--Spacing-2, 0.2rem) var(--Spacing-6, 0.6rem);
    }
  }
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
