import React, { HTMLAttributes } from "react";

import * as S from "./Checkbox.style";

interface Props extends HTMLAttributes<HTMLInputElement> {
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  checked?: boolean;
  checkImageUrl: string;
  isChangedTextColor?: boolean;
  borderWidth?: string;
}

export const Checkbox = ({
  onChangeHandler,
  value,
  checkImageUrl,
  isChangedTextColor = true,
  borderWidth = "0.12rem",
  checked,
  ...props
}: Props) => {
  return (
    <S.Checkbox
      type="checkbox"
      onChange={onChangeHandler}
      checked={checked}
      value={value}
      $imageUrl={checkImageUrl}
      $isChangedTextColor={isChangedTextColor}
      $borderWidth={borderWidth}
      {...props}
    />
  );
};

export default Checkbox;
