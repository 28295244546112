import styled from "styled-components";

export const SimilarDataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 2.5rem;
`;

export const DividedLine = styled.div`
  justify-content: flex-start;
  height: 100%;
  border-right: 1px solid var(--grayscale-spectrum-neural-blue-gray-03, #dee5ef);
  margin-right: 1.2rem;
`;

export const SimilarDataButton = styled.div`
  display: flex;
  color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);
  justify-content: center;
  text-align: center;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 145%;
  padding: var(--Spacing-Btn-8, 0.5rem) var(--Spacing-4, 0.25rem);

  &:hover {
    color: var(--Color-Common-Text-Interactive-Secondary-Hovered, #3d4551);
  }

  &:pressed {
    color: var(--Color-Common-Text-Interactive-Secondary-Pressed, #2a2f37);
  }
`;
