import { useMutation, useQuery, useSuspenseQuery } from "@tanstack/react-query";

import { queryClient } from "@api/queryClient";
import { QUERY_KEYS } from "@api/queryKeys";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import {
  GetMainLabelsResponse,
  MainLabelArticleSectionCode,
  MainLabelListForUpdateType,
} from "./mainLabel.interface";
import { mainLabelApis } from "./mainLabel.service";

export const useGetMainLabelInfoQuery = (
  sectionType: MainLabelArticleSectionCode,
) => {
  const query = useSuspenseQuery({
    queryKey: [QUERY_KEYS.mainLabelInfo, getPressIdFromURL()],
    queryFn: () => mainLabelApis.getInfo(sectionType),
  });

  return query;
};

export const useGetMainLabelQuery = () => {
  const query = useQuery({
    queryKey: [QUERY_KEYS.mainLabel, getPressIdFromURL()],
    queryFn: () => mainLabelApis.getLabels(getPressIdFromURL()),
  });

  return query;
};

export const getMainLabelData = () => {
  const mainLabelData: GetMainLabelsResponse | undefined =
    queryClient.getQueryData([QUERY_KEYS.mainLabel, getPressIdFromURL()]);

  return mainLabelData?.data || [];
};

export const usePostMainLabelMutation = () => {
  return useMutation({
    mutationFn: (mainLabelData: MainLabelListForUpdateType) =>
      mainLabelApis.post(getPressIdFromURL(), mainLabelData),
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: [QUERY_KEYS.mainLabel, getPressIdFromURL()],
      });
    },
  });
};

export const useCreateMainLabelMutation = () => {
  return useMutation({
    mutationFn: (draftArticleIds: string[]) =>
      mainLabelApis.createMainLabel(draftArticleIds),
  });
};
