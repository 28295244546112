import { apiClient } from "@api/apiClient";

import type { PutArticleInfoRequest } from "./dealsOverlappedUpdate.type";
import type { PutArticleInfoResponse } from "@interface/articleInfo.interface";

const API_ENDPOINT = {
  update: "/des/draft-article/update",
};

export const dealsOverlappedUpdateApis = {
  put: async (articleInfo: PutArticleInfoRequest) => {
    const { data } = await apiClient.put<PutArticleInfoResponse>(
      API_ENDPOINT.update,
      articleInfo,
    );

    return data;
  },
};
