export type UnsuitableReasonType = "default" | "writing" | "addId";

export interface UnsuitableReasonList {
  id: string;
  reason: string | null;
  placeholder?: string;
  explanation?: string;
  defaultReason?: string;
  duplId?: number | null;
  type: UnsuitableReasonType;
  isTarget: boolean;
}

export const DEFAULT_VALUE_FOR_ADD_ID_TYPE = {
  overlappedPress: "Overlapped press",
  dealDataIgnore: "Deal data ignore",
};

export const ADD_ID_TYPE_VALUES = Object.values(DEFAULT_VALUE_FOR_ADD_ID_TYPE);

export const UNSUITABLE_REASON_TYPE_INFO: Record<string, UnsuitableReasonType> =
  {
    default: "default",
    writing: "writing",
    addId: "addId",
  };

export const UNSUITABLE_REASON_LIST: UnsuitableReasonList[] = [
  {
    id: "S930-10",
    reason: "venture formation (spin out, joint venture)",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-16",
    reason: "Opening new round",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-11",
    reason: "three party deal",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-15",
    reason: "comment or celebration on deal",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-12",
    reason: "closing, completion",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-13",
    reason: "update, amendment, termination, term sheet, LOI",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-00",
    reason: "To be entered later",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-01",
    reason: "Missing press content",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-02",
    reason: "Not enough information",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-03",
    reason: "Out of scope company",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-04",
    reason: "Out of scope press",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-05",
    reason: "Second target company",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-06",
    reason: "Second target press",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-07",
    reason: "Non-english",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-08",
    reason: DEFAULT_VALUE_FOR_ADD_ID_TYPE.overlappedPress,
    explanation: DEFAULT_VALUE_FOR_ADD_ID_TYPE.overlappedPress,
    defaultReason: DEFAULT_VALUE_FOR_ADD_ID_TYPE.overlappedPress,
    duplId: null,
    placeholder: "Press ID",
    type: UNSUITABLE_REASON_TYPE_INFO.addId,
    isTarget: false,
  },
  {
    id: "S930-09",
    reason: null,
    explanation: "If other, please specify",
    placeholder: "max 30 characters",
    type: UNSUITABLE_REASON_TYPE_INFO.writing,
    isTarget: false,
  },
  {
    id: "S930-14",
    reason: DEFAULT_VALUE_FOR_ADD_ID_TYPE.dealDataIgnore,
    explanation: DEFAULT_VALUE_FOR_ADD_ID_TYPE.dealDataIgnore,
    defaultReason: DEFAULT_VALUE_FOR_ADD_ID_TYPE.dealDataIgnore,
    duplId: null,
    placeholder: "Press ID",
    type: UNSUITABLE_REASON_TYPE_INFO.addId,
    isTarget: false,
  },
];

const DEFAULT_REASON_LIST = UNSUITABLE_REASON_LIST.map(
  ({ reason }) => reason,
).filter((item) => item !== null);

export const DEFAULT_REASON = [...DEFAULT_REASON_LIST];
