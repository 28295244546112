import { useState, useEffect } from "react";

import { changeRAndDUpperCase, IconExternalLink } from "@frontend/common";

import { useDealsOverlappedUpdateMutation } from "@api/\bdealsOverlappedUpdate/dealsOverlappedUpdate";
import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import {
  SimilarPressData,
  TargetPressData,
} from "@api/dealsSimilarData/dealsSimilarData.type";
import { useOverlappedPressCancelMutation } from "@api/unsuitablePress/unsuitablePress";
import { ROUTE_PATHS } from "@routes/routePath";
import { formatDate } from "@utils/formatDate";
import { trimCharacter } from "@utils/trimCharacter";

import * as S from "./SimilarPressTableContainer.style";
import { Checkbox } from "../OverlappedCheckBox/OverlappedCheckBox";

const SIMILARITY_PRESS_REJECT_REASON = "Overlapped press";

const SimilarPressTableContainer = ({
  similarPressData,
  isResizing,
  targetPressData,
}: {
  similarPressData: SimilarPressData[];
  isResizing: boolean;
  targetPressData: TargetPressData;
}) => {
  const [overlappedId, setOverlappedId] = useState<number>();
  const [checked, setChecked] = useState<boolean>(false);
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);

  const { mutateAsync: updateOverlapped } = useDealsOverlappedUpdateMutation();
  const { mutateAsync: cancelOverlapped } = useOverlappedPressCancelMutation();

  const { data: articleInfo } = useArticleInfoQuery();
  const mcEditorName = trimCharacter(articleInfo?.mcEditorName);

  useEffect(() => {
    setOverlappedId(articleInfo?.duplArticleId);
    setChecked(!!articleInfo?.duplArticleId);
  }, [articleInfo]);

  const handleCheckboxChange = async (similarPressId: number) => {
    const newChecked = !checked;
    setChecked(newChecked);

    try {
      setOverlappedId(newChecked ? similarPressId : undefined);
      if (
        similarPressId === articleInfo?.duplArticleId &&
        articleInfo?.articleRejectReason === SIMILARITY_PRESS_REJECT_REASON
      ) {
        await cancelOverlapped(targetPressData.pressId);

        return;
      }
      const targetId = targetPressData.pressId;
      const similarId = similarPressId;
      const isMcEditor = !!mcEditorName;
      await updateOverlapped({
        targetId,
        similarId,
        isMcEditor,
      });
    } catch (error) {
      setChecked(!checked);
      setOverlappedId(articleInfo?.duplArticleId);
    }
  };

  function isWithinFourDays(
    similarPressDate: string,
    targetPressDate: string,
  ): boolean {
    const similarDate = new Date(similarPressDate);
    const targetDate = new Date(targetPressDate);
    const diffInDays: number = Math.abs(
      (targetDate.getTime() - similarDate.getTime()) / (1000 * 60 * 60 * 24),
    );
    return diffInDays <= 4;
  }

  const handleClick = (id: string) => {
    const url = `${ROUTE_PATHS.fillInTable}?id=${id}`;
    window.open(url, "_blank");
  };

  return (
    <S.SimilarPressTableContainer isResizing={isResizing}>
      <S.SimilarPressTableTitle>Similar Press</S.SimilarPressTableTitle>
      <S.SimilarPressTableWrapper>
        <S.SimilarPressTableHeader>
          <S.SimilarPressTablePressId>
            <S.SimilarPressTableHeadDiv>Press ID</S.SimilarPressTableHeadDiv>
          </S.SimilarPressTablePressId>
          <S.SimilarPressTableScore>
            <S.SimilarPressTableHeadDiv>Score</S.SimilarPressTableHeadDiv>
          </S.SimilarPressTableScore>
          <S.SimilarPressTablePressDate>
            <S.SimilarPressTableHeadDiv>Press Date</S.SimilarPressTableHeadDiv>
          </S.SimilarPressTablePressDate>
          <S.SimilarPressTableSeller>
            <S.SimilarPressTableHeadDiv>Seller</S.SimilarPressTableHeadDiv>
          </S.SimilarPressTableSeller>
          <S.SimilarPressTableBuyer>
            <S.SimilarPressTableHeadDiv>Buyer</S.SimilarPressTableHeadDiv>
          </S.SimilarPressTableBuyer>
          <S.SimilarPressTableDealType>
            <S.SimilarPressTableHeadDiv>Deal Type</S.SimilarPressTableHeadDiv>
          </S.SimilarPressTableDealType>
          <S.SimilarPressTablePressTitle>
            <S.SimilarPressTableHeadDiv>Press Title</S.SimilarPressTableHeadDiv>
          </S.SimilarPressTablePressTitle>
          <S.SimilarPressTableUnsuitable>
            <S.SimilarPressTableHeadDiv>Unsuitable</S.SimilarPressTableHeadDiv>
          </S.SimilarPressTableUnsuitable>
        </S.SimilarPressTableHeader>

        {similarPressData?.map((similarPressItem) => {
          return (
            <S.SimilarPressTableBody
              key={similarPressItem.pressId}
              onMouseEnter={() => setHoveredRow(similarPressItem.pressId)}
              onMouseLeave={() => setHoveredRow(null)}
              hovered={hoveredRow === similarPressItem.pressId}
            >
              <S.SimilarPressTablePressIdWrapper>
                <S.SimilarPressId
                  onClick={() => handleClick(`${similarPressItem.pressId}`)}
                >
                  #{similarPressItem.pressId}
                </S.SimilarPressId>
                <S.SimilarPressIcon
                  onClick={() => handleClick(`${similarPressItem.pressId}`)}
                >
                  <IconExternalLink width={20} height={20} />
                </S.SimilarPressIcon>
              </S.SimilarPressTablePressIdWrapper>

              <S.SimilarPressTableScore>
                <span>{similarPressItem.score.toFixed(4)}</span>
              </S.SimilarPressTableScore>

              <S.SimilarPressTablePressDateData>
                <div>
                  <S.SimilarPressCheckDate
                    isWithinFourDays={isWithinFourDays(
                      similarPressItem.pressDate[0]?.text,
                      targetPressData.pressDate[0]?.text,
                    )}
                  >
                    {formatDate(
                      similarPressItem.pressDate[0]?.text,
                      "YYYY.MM.DD",
                    )}
                  </S.SimilarPressCheckDate>
                  {similarPressItem.pressDate.map((pressDate) => (
                    <S.SimilarPressCheckDate
                      isWithinFourDays={isWithinFourDays(
                        similarPressItem.pressDate[0]?.text,
                        targetPressData.pressDate[0]?.text,
                      )}
                    >
                      {pressDate.isManual ? "(Manual)" : ""}
                    </S.SimilarPressCheckDate>
                  ))}
                </div>
              </S.SimilarPressTablePressDateData>

              <S.SimilarPressTableSellerData>
                <S.SimilarPressTableBodyDiv>
                  {similarPressItem.seller.map((seller) => (
                    <S.PressMatchedWrapper matched={seller.matched}>
                      {seller.text}
                    </S.PressMatchedWrapper>
                  ))}
                </S.SimilarPressTableBodyDiv>
              </S.SimilarPressTableSellerData>

              <S.SimilarPressTableBuyerData>
                <S.SimilarPressTableBodyDiv>
                  {similarPressItem.buyer.map((buyer) => (
                    <S.PressMatchedWrapper matched={buyer.matched}>
                      {buyer.text}
                    </S.PressMatchedWrapper>
                  ))}
                </S.SimilarPressTableBodyDiv>
              </S.SimilarPressTableBuyerData>

              <S.SimilarPressTableDealTypeData>
                <S.SimilarPressTableBodyDiv>
                  {similarPressItem.dealType.map((dealType) => (
                    <S.PressMatchedWrapper matched={dealType.matched}>
                      {changeRAndDUpperCase(dealType.text)}
                    </S.PressMatchedWrapper>
                  ))}
                </S.SimilarPressTableBodyDiv>
              </S.SimilarPressTableDealTypeData>

              <S.SimilarPressTablePressTitleData>
                <S.SimilarPressTablePressTitleOverflow>
                  {similarPressItem.pressTitle}
                </S.SimilarPressTablePressTitleOverflow>
              </S.SimilarPressTablePressTitleData>
              <S.SimilarPressTableUnsuitableData>
                <div>
                  <div>Overlapped</div>
                  <S.CheckBoxContainer>
                    <Checkbox
                      onChangeHandler={() =>
                        handleCheckboxChange(similarPressItem.pressId)
                      }
                      value={similarPressItem.pressId}
                      checked={similarPressItem.pressId === overlappedId}
                    />
                  </S.CheckBoxContainer>
                </div>
              </S.SimilarPressTableUnsuitableData>
            </S.SimilarPressTableBody>
          );
        })}
      </S.SimilarPressTableWrapper>
    </S.SimilarPressTableContainer>
  );
};

export default SimilarPressTableContainer;
