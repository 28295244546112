export type Section =
  | "clinicalTrials"
  | "regulatory"
  | "deals"
  | "expeditedProgram"
  | "ventureCapital";

export type SectionCode =
  | "TY001"
  | "TY002"
  | "TY003"
  | "TY004"
  | "TY005"
  | "TY006";

export const SECTION_ACCORDING_TO_CLASSIFICATION = {
  C000: "clinicalTrials",
  C001: "clinicalTrialStatus",
  C003: "clinicalTrials",
  C004: "clinicalTrials",
  C005: "clinicalTrials",
  C006: "deals",
  C007: "clinicalTrials",
  C008: "clinicalTrials",
  C009: "clinicalTrials",
  C010: "clinicalTrials",
  C011: "clinicalTrials",
  C012: "clinicalTrials",
  C013: "regulatory",
  C014: "clinicalTrials",
  C015: "clinicalTrials",
  C016: "expeditedProgram",
  C017: "clinicalTrials",
  C018: "clinicalTrials",
  C019: "clinicalTrials",
  C020: "clinicalTrials",
  C021: "ventureCapital",
  C022: "clinicalTrials",
  C023: "clinicalTrials",
};

export const SECTION_CODE_ACCORDING_TO_CLASSIFICATION = {
  C000: "TY001",
  C001: "TY005",
  C003: "TY001",
  C004: "TY001",
  C005: "TY001",
  C006: "TY002",
  C007: "TY001",
  C008: "TY001",
  C009: "TY001",
  C010: "TY001",
  C011: "TY001",
  C012: "TY001",
  C013: "TY003",
  C014: "TY001",
  C015: "TY001",
  C016: "TY004",
  C017: "TY001",
  C018: "TY001",
  C019: "TY001",
  C020: "TY001",
  C021: "TY006",
  C022: "TY001",
  C023: "TY001",
  "": "TY001",
} as const;

export const SECTION_NAME = {
  "": "",
  C000: "Clinical Trial",
  C001: "Clinical Trial Status",
  C003: "",
  C004: "",
  C005: "Clinical Trial",
  C006: "Deals",
  C007: "",
  C008: "",
  C009: "",
  C010: "",
  C011: "",
  C012: "",
  C013: "Regulatory",
  C014: "",
  C015: "",
  C016: "Expedited Program",
  C017: "",
  C018: "",
  C019: "",
  C020: "",
  C021: "Venture Capital",
  C022: "",
  C023: "",
} as const;

export const SECTION_NAME_FOR_DISPLAY_ARTICLE_SECTION = {
  "": "",
  C000: "Clinical Trial",
  C001: "",
  C003: "",
  C004: "",
  C005: "Clinical Trial",
  C006: "Deals",
  C007: "",
  C008: "",
  C009: "",
  C010: "",
  C011: "",
  C012: "",
  C013: "Regulatory",
  C014: "",
  C015: "",
  C016: "Expedited Program",
  C017: "",
  C018: "",
  C019: "",
  C020: "",
  C021: "Venture Capital",
  C022: "",
  C023: "",
} as const;

export const SECTION_CODE_BY_NAME = {
  clinicalTrials: "TY001",
  deals: "TY002",
  regulatory: "TY003",
  expeditedProgram: "TY004",
  clinicalTrialStatus: "TY005",
  ventureCapital: "TY006",
};

export const SECTION_CODE_BY_NAME_FOR_DISPLAY_ARTICLE_SECTION: Record<
  string,
  SectionCode
> = {
  "clinical trials": "TY001",
  deals: "TY002",
  regulatory: "TY003",
  "expedited program": "TY004",
  "clinical trial status": "TY005",
  "venture capital": "TY006",
};

export const SECTION_TITLE_BY_SECTION_CODE: Record<SectionCode, string> = {
  TY001: "Clinical Trials",
  TY002: "Deals",
  TY003: "Regulatory",
  TY004: "Regulatory",
  TY005: "Clinical Trial Status",
  TY006: "Venture Capital",
};

export const SECTION_PAGE_PATH: Partial<Record<SectionCode, string>> = {
  TY001: "clinical-trials",
  TY002: "deals",
  TY003: "regulatory",
  TY004: "regulatory",
  TY006: "venture-capital",
};
