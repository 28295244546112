import {
  ENTRY_DATA_SECTION_KEYS as SECTION_KEYS,
  ENTRY_DATA_DRUG_PROFILE_GROUP_TABLE_KEY as DRUG_PROFILE,
  ENTRY_DATA_INVESTORS_GROUP_TABLE_KEY as INVESTORS,
  ENTRY_DATA_INVESTORS_SHELL_KEYS as INVESTORS_SHELL_KEYS,
  LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS,
  ENTRY_DATA_SECTION_KEYS,
  ENTRY_DATA_LICENSEE_TABLE,
  ENTRY_DATA_LICENSEE_TABLE_KEYS,
  ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS,
  MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS,
  ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS,
  MERGE_ACQUISITION_SHELL_KEYS,
  CLINICAL_TRIAL_DESIGN_SUB_GROUP_SHELL_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS,
  ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS,
  ENTRY_DATA_DEAL_TYPE_AND_STATUS_KEYS,
  ENTRY_DATA_DEAL_TYPE_AND_STATUS,
  ENTRY_DATA_LICENSOR_TABLE_KEYS,
  ENTRY_DATA_LICENSOR_TABLE,
  ENTRY_DATA_RIGHTS_SCOPE_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS,
  ENTRY_DATA_CLINICAL_RESULTS_KEYS,
  ENTRY_DATA_CLINICAL_RESULTS,
  ENTRY_DATA_CLINICAL_ENDPOINTS_DESCRIPTION_KEYS,
  ENTRY_DATA_CLINICAL_ENDPOINTS_DESCRIPTION,
  ENTRY_DATA_APPROVAL_PROCESS_CONTENTS_KEYS,
  ENTRY_DATA_APPROVAL_PROCESS_KEYS,
  ENTRY_DATA_APPROVAL_PROCESS_CONTENTS,
  ENTRY_DATA_ROUND_TYPE_AND_STATUS_KEYS,
  ENTRY_DATA_ROUND_TYPE_AND_STATUS_GROUP_TABLE_KEY,
  ENTRY_DATA_FUNDED_COMPANY_SHELL_KEYS,
  ENTRY_DATA_FUNDED_COMPANY_GROUP_TABLE_KEY,
  ENTRY_DATA_FINANCING_INFORMATION_SHELL_KEYS,
  ENTRY_DATA_FINANCING_INFORMATION_GROUP_TABLE_KEY,
  ENTRY_DATA_ADDITIONAL_INFORMATION_VENTURE_CAPITAL_KEYS,
  ENTRY_DATA_ADDITIONAL_INFORMATION_VENTURE_CAPITAL_GROUP_TABLE_KEY,
  ENTRY_DATA_EXPEDITED_PROGRAM_CONTENTS_KEYS,
  ENTRY_DATA_EXPEDITED_PROGRAM_CONTENTS,
} from "@frontend/common";

import { ArticleEntryData } from "@/types/articleEntryData.types";
import { DrugProfileValue, Endpoints } from "@/types/clinicalTrials.types";
import { DealsDrugProfileValue, EntryDataValueInfo } from "@/types/deals.types";
import { ExpeditedProgramDrugProfile } from "@/types/expeditedProgram.types";
import { RegulatoryDrugProfile } from "@/types/regulatory.types";
import { VentureCapitalDrugProfile } from "@/types/ventureCapital.types";

import {
  EXPEDITED_PROGRAM,
  EXPEDITED_PROGRAM_GROUP_KEY as eg,
} from "./expeditedProgramKey.constants";
import { moveItemToPosition } from "../utils/changeEntryDataOrder";
import { dragChangeDrugProfileValueOrder } from "../utils/dragChangeDrugProfileValueOrder";

type DragInfoType = [number | null, number | null];

export const ENTRY_DATA_TO_CHANGE_TABLE_ORDER: Record<
  string,
  Record<
    string,
    (data: ArticleEntryData, draggedIndexInfo: DragInfoType) => ArticleEntryData
  >
> = {
  "Clinical Trials": {
    "Drug Profile": (data, draggedIndexInfo) => {
      return {
        ...data,
        "Clinical Trials": {
          ...data?.["Clinical Trials"],
          "Drug Profile": moveItemToPosition(
            data?.["Clinical Trials"]?.["Drug Profile"] || [],
            draggedIndexInfo[0] || 0,
            draggedIndexInfo[1] || 0,
          ) as DrugProfileValue[],
        },
      } as ArticleEntryData;
    },
    [ENTRY_DATA_CLINICAL_TRIAL_DESIGN]: (data, draggedIndexInfo) => {
      return {
        ...data,
        [ENTRY_DATA_SECTION_KEYS.clinicalTrial]: {
          ...data?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial],
          [ENTRY_DATA_CLINICAL_TRIAL_DESIGN]: moveItemToPosition(
            data?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
              ENTRY_DATA_CLINICAL_TRIAL_DESIGN
            ] || [],
            draggedIndexInfo[0] || 0,
            draggedIndexInfo[1] || 0,
          ),
        },
      } as ArticleEntryData;
    },
    "Clinical Endpoints (Table)": (data, draggedIndexInfo) => {
      return {
        ...data,
        "Clinical Trials": {
          ...data?.["Clinical Trials"],
          "Clinical Endpoints (Table)": moveItemToPosition(
            data?.["Clinical Trials"]?.["Clinical Endpoints (Table)"] || [],
            draggedIndexInfo[0] || 0,
            draggedIndexInfo[1] || 0,
          ) as Endpoints[],
        },
      };
    },
  },
  "Clinical Trial Status": {
    "Drug Profile": (data, draggedIndexInfo) => {
      return {
        ...data,
        "Clinical Trial Status": {
          ...data?.["Clinical Trial Status"],
          "Drug Profile": moveItemToPosition(
            data?.["Clinical Trial Status"]?.["Drug Profile"] || [],
            draggedIndexInfo[0] || 0,
            draggedIndexInfo[1] || 0,
          ) as DrugProfileValue[],
        },
      } as ArticleEntryData;
    },
    // "Clinical Trial Design-1": (data, draggedIndexInfo) => {
    //   return {
    //     ...data,
    //     "Clinical Trial Status": {
    //       ...data?.["Clinical Trial Status"],
    //       "Clinical Trial Design-1": moveItemToPosition(
    //         data?.["Clinical Trial Status"]?.["Clinical Trial Design-1"] || [],
    //         draggedIndexInfo[0] || 0,
    //         draggedIndexInfo[1] || 0,
    //       ) as ClinicalTrialDesign1,
    //     },
    //   } as ArticleEntryData;
    // },
  },
  Regulatory: {
    "Drug Profile": (data, draggedIndexInfo) => {
      return {
        ...data,
        Regulatory: {
          ...data?.Regulatory,
          "Drug Profile": moveItemToPosition(
            data?.Regulatory?.["Drug Profile"] || [],
            draggedIndexInfo[0] || 0,
            draggedIndexInfo[1] || 0,
          ) as RegulatoryDrugProfile[],
        },
      } as ArticleEntryData;
    },
  },
  Deals: {
    "Drug Profile": (data, draggedIndexInfo) => {
      return {
        ...data,
        Deals: {
          ...data?.Deals,
          "Drug Profile": moveItemToPosition(
            data?.Deals?.["Drug Profile"] || [],
            draggedIndexInfo[0] || 0,
            draggedIndexInfo[1] || 0,
          ) as DealsDrugProfileValue[],
        },
      } as ArticleEntryData;
    },
    "Licensee Table": (data, draggedIndexInfo) => {
      return {
        ...data,
        Deals: {
          ...data?.Deals,
          "Licensee Table": moveItemToPosition(
            data?.Deals?.["Licensee Table"] || [],
            draggedIndexInfo[0] || 0,
            draggedIndexInfo[1] || 0,
          ),
        },
      } as ArticleEntryData;
    },
  },
  [EXPEDITED_PROGRAM]: {
    [eg.drugProfile]: (data, draggedIndexInfo) => {
      return {
        ...data,
        [EXPEDITED_PROGRAM]: {
          ...data?.[EXPEDITED_PROGRAM],
          [eg.drugProfile]: moveItemToPosition(
            data?.[EXPEDITED_PROGRAM]?.[eg.drugProfile] || [],
            draggedIndexInfo[0] || 0,
            draggedIndexInfo[1] || 0,
          ) as ExpeditedProgramDrugProfile[],
        },
      } as ArticleEntryData;
    },
  },
  [SECTION_KEYS.ventureCapital]: {
    [DRUG_PROFILE]: (data, draggedIndexInfo) => {
      return {
        ...data,
        [SECTION_KEYS.ventureCapital]: {
          ...data?.[SECTION_KEYS.ventureCapital],
          [DRUG_PROFILE]: moveItemToPosition(
            data?.[SECTION_KEYS.ventureCapital]?.[DRUG_PROFILE] || [],
            draggedIndexInfo[0] || 0,
            draggedIndexInfo[1] || 0,
          ) as VentureCapitalDrugProfile[],
        },
      } as ArticleEntryData;
    },
  },
};

export const ENTRY_DATA_TO_CHANGE_GROUP_SHELL_ORDER: Record<
  string,
  Record<
    string,
    (
      data: ArticleEntryData,
      draggedIndexInfo: DragInfoType,
      tableIndex?: number,
    ) => ArticleEntryData
  >
> = {
  Deals: {
    "Licensee Table": (data, draggedIndexInfo, tableIndex) => {
      return {
        ...data,
        Deals: {
          ...data.Deals,
          "Licensee Table": data.Deals?.["Licensee Table"]?.map(
            (tableData, currentTableIndex) => {
              if (currentTableIndex === tableIndex) {
                return {
                  ...tableData,
                  "Rights Scope": moveItemToPosition(
                    tableData["Rights Scope"] || [],
                    draggedIndexInfo[0] || 0,
                    draggedIndexInfo[1] || 0,
                  ),
                };
              }

              return tableData;
            },
          ),
        },
      } as ArticleEntryData;
    },
  },
};

export const ENTRY_DATA_TO_CHANGE_SUB_GROUP_SHELL_ORDER: Record<
  string,
  Record<
    string,
    (
      data: ArticleEntryData,
      draggedIndexInfo: DragInfoType,
      tableIndex?: number,
    ) => ArticleEntryData
  >
> = {
  Deals: {
    [LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.milestone]: (
      data,
      draggedIndexInfo,
      tableIndex,
    ) => {
      return {
        ...data,
        Deals: {
          ...data.Deals,
          "Licensee Table": data.Deals?.["Licensee Table"]?.map(
            (tableData, currentTableIndex) => {
              if (currentTableIndex === tableIndex) {
                return {
                  ...tableData,
                  "Strategic Collaboration & License Agreement terms": {
                    ...tableData[
                      "Strategic Collaboration & License Agreement terms"
                    ],
                    "Milestone Payment and Condition": moveItemToPosition(
                      tableData[
                        "Strategic Collaboration & License Agreement terms"
                      ]["Milestone Payment and Condition"] || [],
                      draggedIndexInfo[0] || 0,
                      draggedIndexInfo[1] || 0,
                    ),
                  },
                };
              }

              return tableData;
            },
          ),
        },
      } as ArticleEntryData;
    },
    [LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.otherPaymentAndDetail]: (
      data,
      draggedIndexInfo,
      tableIndex,
    ) => {
      return {
        ...data,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...data.Deals,
          [ENTRY_DATA_LICENSEE_TABLE]: data?.[ENTRY_DATA_SECTION_KEYS.deals]?.[
            ENTRY_DATA_LICENSEE_TABLE
          ]?.map((tableData, currentTableIndex) => {
            if (currentTableIndex === tableIndex) {
              return {
                ...tableData,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.strategicCollaborationLicenseAgreementTerms]:
                  {
                    ...tableData[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS
                        .strategicCollaborationLicenseAgreementTerms
                    ],
                    [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.otherPaymentAndDetailScLa]:
                      moveItemToPosition(
                        tableData[
                          ENTRY_DATA_LICENSEE_TABLE_KEYS
                            .strategicCollaborationLicenseAgreementTerms
                        ][
                          ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                            .otherPaymentAndDetailScLa
                        ] || [],
                        draggedIndexInfo[0] || 0,
                        draggedIndexInfo[1] || 0,
                      ),
                  },
              };
            }

            return tableData;
          }),
        },
      } as ArticleEntryData;
    },

    [MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS.contingentPayment]: (
      data,
      draggedIndexInfo,
      tableIndex,
    ) => {
      return {
        ...data,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...data.Deals,
          [ENTRY_DATA_LICENSEE_TABLE]: data[ENTRY_DATA_SECTION_KEYS.deals]?.[
            ENTRY_DATA_LICENSEE_TABLE
          ]?.map((tableData, currentTableIndex) => {
            if (currentTableIndex === tableIndex) {
              return {
                ...tableData,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms]: {
                  ...tableData[
                    ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                  ],
                  [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.contingentPayment]:
                    moveItemToPosition(
                      tableData[
                        ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                      ][
                        ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                          .contingentPayment
                      ] || [],
                      draggedIndexInfo[0] || 0,
                      draggedIndexInfo[1] || 0,
                    ),
                },
              };
            }

            return tableData;
          }),
        },
      } as ArticleEntryData;
    },
  },
  [ENTRY_DATA_SECTION_KEYS.clinicalTrial]: {
    [CLINICAL_TRIAL_DESIGN_SUB_GROUP_SHELL_KEYS.arm]: (
      data,
      draggedIndexInfo,
      tableIndex,
    ) => {
      return {
        ...data,
        [ENTRY_DATA_SECTION_KEYS.clinicalTrial]: {
          ...data[ENTRY_DATA_SECTION_KEYS.clinicalTrial],
          [ENTRY_DATA_CLINICAL_TRIAL_DESIGN]: data[
            ENTRY_DATA_SECTION_KEYS.clinicalTrial
          ]?.[ENTRY_DATA_CLINICAL_TRIAL_DESIGN]?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableIndex === tableInfoIndex)
                return {
                  ...tableInfo,
                  [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2]:
                    moveItemToPosition(
                      tableInfo[
                        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                          .clinicalTrialDesign2
                      ] || [],
                      draggedIndexInfo[0] || 0,
                      draggedIndexInfo[1] || 0,
                    ),
                };

              return tableInfo;
            },
          ),
          "Licensee Table": data.Deals?.["Licensee Table"]?.map(
            (tableData, currentTableIndex) => {
              if (currentTableIndex === tableIndex) {
                return {
                  ...tableData,
                  "Strategic Collaboration & License Agreement terms": {
                    ...tableData[
                      "Strategic Collaboration & License Agreement terms"
                    ],
                    "Milestone Payment and Condition": moveItemToPosition(
                      tableData[
                        "Strategic Collaboration & License Agreement terms"
                      ]["Milestone Payment and Condition"] || [],
                      draggedIndexInfo[0] || 0,
                      draggedIndexInfo[1] || 0,
                    ),
                  },
                };
              }

              return tableData;
            },
          ),
        },
      } as ArticleEntryData;
    },
  },
};

type IndexType = {
  tableIndex: number;
  groupShellIndex: number;
  subGroupShellIndex?: number | null;
};

export const ENTRY_DATA_TO_CHANGE_VALUE_ORDER: Record<
  string,
  Record<
    string,
    (
      data: ArticleEntryData,
      draggedIndexInfo: DragInfoType,
      positionIndex?: IndexType,
      isLicenseAgreement?: boolean,
    ) => ArticleEntryData
  >
> = {
  Deals: {
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.brandName]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.deals,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.brandName,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.innName]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.deals,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.innName,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.codeName]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.deals,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.codeName,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.roa]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.deals,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.roa,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.modality]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.deals,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.modality,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.target]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.deals,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.target,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.moa]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.deals,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.moa,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.targetIndication]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.deals,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.targetIndication,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.therapeuticArea]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.deals,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.therapeuticArea,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.otherCharacteristics]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.deals,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.otherCharacteristics,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.phase]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.deals,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.phase,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.refProductName]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.deals,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.refProductName,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.originatorDeveloper]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.deals,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.originatorDeveloper,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DEAL_TYPE_AND_STATUS_KEYS.dealName]: (
      data,
      draggedIndexInfo,
    ) => {
      return {
        ...data,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...data[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_DEAL_TYPE_AND_STATUS]: {
            ...data?.[ENTRY_DATA_SECTION_KEYS.deals]?.[
              ENTRY_DATA_DEAL_TYPE_AND_STATUS
            ],
            [ENTRY_DATA_DEAL_TYPE_AND_STATUS_KEYS.dealName]: {
              ...data?.[ENTRY_DATA_SECTION_KEYS.deals]?.[
                ENTRY_DATA_DEAL_TYPE_AND_STATUS
              ]?.[ENTRY_DATA_DEAL_TYPE_AND_STATUS_KEYS.dealName],
              value: moveItemToPosition(
                data?.[ENTRY_DATA_SECTION_KEYS.deals]?.[
                  ENTRY_DATA_DEAL_TYPE_AND_STATUS
                ]?.[ENTRY_DATA_DEAL_TYPE_AND_STATUS_KEYS.dealName].value || [],
                draggedIndexInfo[0] || 0,
                draggedIndexInfo[1] || 0,
              ),
            },
          },
        },
      } as ArticleEntryData;
    },
    [ENTRY_DATA_DEAL_TYPE_AND_STATUS_KEYS.dealType]: (
      data,
      draggedIndexInfo,
    ) => {
      return {
        ...data,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...data[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_DEAL_TYPE_AND_STATUS]: {
            ...data?.[ENTRY_DATA_SECTION_KEYS.deals]?.[
              ENTRY_DATA_DEAL_TYPE_AND_STATUS
            ],
            [ENTRY_DATA_DEAL_TYPE_AND_STATUS_KEYS.dealType]: {
              ...data?.[ENTRY_DATA_SECTION_KEYS.deals]?.[
                ENTRY_DATA_DEAL_TYPE_AND_STATUS
              ]?.[ENTRY_DATA_DEAL_TYPE_AND_STATUS_KEYS.dealType],
              value: moveItemToPosition(
                data?.[ENTRY_DATA_SECTION_KEYS.deals]?.[
                  ENTRY_DATA_DEAL_TYPE_AND_STATUS
                ]?.[ENTRY_DATA_DEAL_TYPE_AND_STATUS_KEYS.dealType].value || [],
                draggedIndexInfo[0] || 0,
                draggedIndexInfo[1] || 0,
              ),
            },
          },
        },
      } as ArticleEntryData;
    },
    [ENTRY_DATA_LICENSOR_TABLE_KEYS.licensorName]: (data, draggedIndexInfo) => {
      return {
        ...data,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...data[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSOR_TABLE]: {
            ...data[ENTRY_DATA_SECTION_KEYS.deals]?.[ENTRY_DATA_LICENSOR_TABLE],
            [ENTRY_DATA_LICENSOR_TABLE_KEYS.licensorName]: {
              ...data[ENTRY_DATA_SECTION_KEYS.deals]?.[
                ENTRY_DATA_LICENSOR_TABLE
              ]?.[ENTRY_DATA_LICENSOR_TABLE_KEYS.licensorName],
              value: moveItemToPosition(
                data[ENTRY_DATA_SECTION_KEYS.deals]?.[
                  ENTRY_DATA_LICENSOR_TABLE
                ]?.[ENTRY_DATA_LICENSOR_TABLE_KEYS.licensorName].value || [],
                draggedIndexInfo[0] || 0,
                draggedIndexInfo[1] || 0,
              ),
            },
          },
        },
      } as ArticleEntryData;
    },
    [ENTRY_DATA_LICENSOR_TABLE_KEYS.licensorResponsibilityRights]: (
      data,
      draggedIndexInfo,
    ) => {
      return {
        ...data,
        Deals: {
          ...data.Deals,
          [ENTRY_DATA_LICENSOR_TABLE]: {
            ...data[ENTRY_DATA_SECTION_KEYS.deals]?.[ENTRY_DATA_LICENSOR_TABLE],
            [ENTRY_DATA_LICENSOR_TABLE_KEYS.licensorResponsibilityRights]: {
              ...data[ENTRY_DATA_SECTION_KEYS.deals]?.[
                ENTRY_DATA_LICENSOR_TABLE
              ]?.[ENTRY_DATA_LICENSOR_TABLE_KEYS.licensorResponsibilityRights],
              value: moveItemToPosition(
                data.Deals?.["Licensor Table"]?.[
                  ENTRY_DATA_LICENSOR_TABLE_KEYS.licensorResponsibilityRights
                ].value as EntryDataValueInfo[],
                draggedIndexInfo[0] || 0,
                draggedIndexInfo[1] || 0,
              ),
            },
          },
        },
      } as ArticleEntryData;
    },
    [ENTRY_DATA_LICENSEE_TABLE_KEYS.licenseeName]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) => {
      return {
        ...data,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...data[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: data[ENTRY_DATA_SECTION_KEYS.deals]?.[
            ENTRY_DATA_LICENSEE_TABLE
          ]?.map((tableData, currentTableIndex) => {
            if (currentTableIndex === positionIndex?.tableIndex) {
              return {
                ...tableData,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.licenseeName]: {
                  ...tableData[ENTRY_DATA_LICENSEE_TABLE_KEYS.licenseeName],
                  value: moveItemToPosition(
                    tableData[ENTRY_DATA_LICENSEE_TABLE_KEYS.licenseeName]
                      .value,
                    draggedIndexInfo[0] || 0,
                    draggedIndexInfo[1] || 0,
                  ),
                },
              };
            }

            return tableData;
          }),
        },
      } as ArticleEntryData;
    },
    [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.licenseeResponsibilityAndRightsScLa]:
      (data, draggedIndexInfo, positionIndex) => {
        return {
          ...data,
          [ENTRY_DATA_SECTION_KEYS.deals]: {
            ...data[ENTRY_DATA_SECTION_KEYS.deals],
            [ENTRY_DATA_LICENSEE_TABLE]: data[ENTRY_DATA_SECTION_KEYS.deals]?.[
              ENTRY_DATA_LICENSEE_TABLE
            ]?.map((tableData, currentTableIndex) => {
              if (currentTableIndex === positionIndex?.tableIndex) {
                return {
                  ...tableData,
                  [ENTRY_DATA_LICENSEE_TABLE_KEYS.strategicCollaborationLicenseAgreementTerms]:
                    {
                      ...tableData[
                        ENTRY_DATA_LICENSEE_TABLE_KEYS
                          .strategicCollaborationLicenseAgreementTerms
                      ],
                      [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.licenseeResponsibilityAndRightsScLa]:
                        {
                          ...tableData[
                            ENTRY_DATA_LICENSEE_TABLE_KEYS
                              .strategicCollaborationLicenseAgreementTerms
                          ][
                            ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                              .licenseeResponsibilityAndRightsScLa
                          ],
                          value: moveItemToPosition(
                            tableData[
                              ENTRY_DATA_LICENSEE_TABLE_KEYS
                                .strategicCollaborationLicenseAgreementTerms
                            ][
                              ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                                .licenseeResponsibilityAndRightsScLa
                            ].value,
                            draggedIndexInfo[0] || 0,
                            draggedIndexInfo[1] || 0,
                          ),
                        },
                    },
                };
              }

              return tableData;
            }),
          },
        } as ArticleEntryData;
      },
    [ENTRY_DATA_RIGHTS_SCOPE_KEYS.drug]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) => {
      return {
        ...data,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...data[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: data[ENTRY_DATA_SECTION_KEYS.deals]?.[
            ENTRY_DATA_LICENSEE_TABLE
          ]?.map((tableData, currentTableIndex) => {
            if (currentTableIndex === positionIndex?.tableIndex) {
              return {
                ...tableData,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.rightsScope]: tableData?.[
                  ENTRY_DATA_LICENSEE_TABLE_KEYS.rightsScope
                ].map((groupShellData, groupShellDataIndex) => {
                  if (groupShellDataIndex === positionIndex?.groupShellIndex) {
                    return {
                      ...groupShellData,
                      [ENTRY_DATA_RIGHTS_SCOPE_KEYS.drug]: {
                        ...groupShellData[ENTRY_DATA_RIGHTS_SCOPE_KEYS.drug],
                        value: moveItemToPosition(
                          groupShellData[ENTRY_DATA_RIGHTS_SCOPE_KEYS.drug]
                            .value,
                          draggedIndexInfo[0] || 0,
                          draggedIndexInfo[1] || 0,
                        ),
                      },
                    };
                  }

                  return groupShellData;
                }),
              };
            }

            return tableData;
          }),
        },
      } as ArticleEntryData;
    },
    [ENTRY_DATA_RIGHTS_SCOPE_KEYS.indication]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) => {
      return {
        ...data,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...data[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: data[ENTRY_DATA_SECTION_KEYS.deals]?.[
            ENTRY_DATA_LICENSEE_TABLE
          ]?.map((tableData, currentTableIndex) => {
            if (currentTableIndex === positionIndex?.tableIndex) {
              return {
                ...tableData,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.rightsScope]: tableData?.[
                  ENTRY_DATA_LICENSEE_TABLE_KEYS.rightsScope
                ].map((groupShellData, groupShellDataIndex) => {
                  if (groupShellDataIndex === positionIndex?.groupShellIndex) {
                    return {
                      ...groupShellData,
                      [ENTRY_DATA_RIGHTS_SCOPE_KEYS.indication]: {
                        ...groupShellData[
                          ENTRY_DATA_RIGHTS_SCOPE_KEYS.indication
                        ],
                        value: moveItemToPosition(
                          groupShellData[
                            ENTRY_DATA_RIGHTS_SCOPE_KEYS.indication
                          ].value,
                          draggedIndexInfo[0] || 0,
                          draggedIndexInfo[1] || 0,
                        ),
                      },
                    };
                  }

                  return groupShellData;
                }),
              };
            }

            return tableData;
          }),
        },
      } as ArticleEntryData;
    },
    [ENTRY_DATA_RIGHTS_SCOPE_KEYS.territory]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) => {
      return {
        ...data,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...data[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: data[ENTRY_DATA_SECTION_KEYS.deals]?.[
            ENTRY_DATA_LICENSEE_TABLE
          ]?.map((tableData, currentTableIndex) => {
            if (currentTableIndex === positionIndex?.tableIndex) {
              return {
                ...tableData,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.rightsScope]: tableData?.[
                  ENTRY_DATA_LICENSEE_TABLE_KEYS.rightsScope
                ].map((groupShellData, groupShellDataIndex) => {
                  if (groupShellDataIndex === positionIndex?.groupShellIndex) {
                    return {
                      ...groupShellData,
                      [ENTRY_DATA_RIGHTS_SCOPE_KEYS.territory]: {
                        ...groupShellData[
                          ENTRY_DATA_RIGHTS_SCOPE_KEYS.territory
                        ],
                        value: moveItemToPosition(
                          groupShellData[ENTRY_DATA_RIGHTS_SCOPE_KEYS.territory]
                            .value,
                          draggedIndexInfo[0] || 0,
                          draggedIndexInfo[1] || 0,
                        ),
                      },
                    };
                  }

                  return groupShellData;
                }),
              };
            }

            return tableData;
          }),
        },
      } as ArticleEntryData;
    },
    [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.otherFinancialTermsScLa]:
      (data, draggedIndexInfo, positionIndex) => {
        return {
          ...data,
          [ENTRY_DATA_SECTION_KEYS.deals]: {
            ...data[ENTRY_DATA_SECTION_KEYS.deals],
            [ENTRY_DATA_LICENSEE_TABLE]: data[ENTRY_DATA_SECTION_KEYS.deals]?.[
              ENTRY_DATA_LICENSEE_TABLE
            ]?.map((tableData, currentTableIndex) => {
              if (currentTableIndex === positionIndex?.tableIndex) {
                return {
                  ...tableData,
                  [ENTRY_DATA_LICENSEE_TABLE_KEYS.strategicCollaborationLicenseAgreementTerms]:
                    {
                      ...tableData[
                        ENTRY_DATA_LICENSEE_TABLE_KEYS
                          .strategicCollaborationLicenseAgreementTerms
                      ],
                      [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.otherFinancialTermsScLa]:
                        {
                          ...tableData[
                            ENTRY_DATA_LICENSEE_TABLE_KEYS
                              .strategicCollaborationLicenseAgreementTerms
                          ][
                            ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                              .otherFinancialTermsScLa
                          ],
                          value: moveItemToPosition(
                            tableData[
                              ENTRY_DATA_LICENSEE_TABLE_KEYS
                                .strategicCollaborationLicenseAgreementTerms
                            ][
                              ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                                .otherFinancialTermsScLa
                            ].value,
                            draggedIndexInfo[0] || 0,
                            draggedIndexInfo[1] || 0,
                          ),
                        },
                    },
                };
              }

              return tableData;
            }),
          },
        } as ArticleEntryData;
      },
    [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.royalty]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) => {
      return {
        ...data,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...data[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: data[ENTRY_DATA_SECTION_KEYS.deals]?.[
            ENTRY_DATA_LICENSEE_TABLE
          ]?.map((tableData, currentTableIndex) => {
            if (currentTableIndex === positionIndex?.tableIndex) {
              return {
                ...tableData,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.strategicCollaborationLicenseAgreementTerms]:
                  {
                    ...tableData[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS
                        .strategicCollaborationLicenseAgreementTerms
                    ],
                    [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.royalty]:
                      {
                        ...tableData[
                          ENTRY_DATA_LICENSEE_TABLE_KEYS
                            .strategicCollaborationLicenseAgreementTerms
                        ][
                          ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                            .royalty
                        ],
                        value: moveItemToPosition(
                          tableData[
                            ENTRY_DATA_LICENSEE_TABLE_KEYS
                              .strategicCollaborationLicenseAgreementTerms
                          ][
                            ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                              .royalty
                          ].value,
                          draggedIndexInfo[0] || 0,
                          draggedIndexInfo[1] || 0,
                        ),
                      },
                  },
              };
            }

            return tableData;
          }),
        },
      } as ArticleEntryData;
    },
    "Licensee Responsibility & Rights (MA)": (
      data,
      draggedIndexInfo,
      positionIndex,
    ) => {
      return {
        ...data,
        Deals: {
          ...data.Deals,
          "Licensee Table": data.Deals?.["Licensee Table"]?.map(
            (tableData, currentTableIndex) => {
              if (currentTableIndex === positionIndex?.tableIndex) {
                return {
                  ...tableData,
                  "Merge & Acquisition terms": {
                    ...tableData["Merge & Acquisition terms"],
                    "Licensee Responsibility & Rights (MA)": {
                      ...tableData["Merge & Acquisition terms"][
                        "Licensee Responsibility & Rights (MA)"
                      ],
                      value: moveItemToPosition(
                        tableData["Merge & Acquisition terms"][
                          "Licensee Responsibility & Rights (MA)"
                        ].value,
                        draggedIndexInfo[0] || 0,
                        draggedIndexInfo[1] || 0,
                      ),
                    },
                  },
                };
              }

              return tableData;
            },
          ),
        },
      } as ArticleEntryData;
    },
    "Other Financial Terms (MA)": (data, draggedIndexInfo, positionIndex) => {
      return {
        ...data,
        Deals: {
          ...data.Deals,
          "Licensee Table": data.Deals?.["Licensee Table"]?.map(
            (tableData, currentTableIndex) => {
              if (currentTableIndex === positionIndex?.tableIndex) {
                return {
                  ...tableData,
                  "Merge & Acquisition terms": {
                    ...tableData["Merge & Acquisition terms"],
                    "Other Financial Terms (MA)": {
                      ...tableData["Merge & Acquisition terms"][
                        "Other Financial Terms (MA)"
                      ],
                      value: moveItemToPosition(
                        tableData["Merge & Acquisition terms"][
                          "Other Financial Terms (MA)"
                        ].value,
                        draggedIndexInfo[0] || 0,
                        draggedIndexInfo[1] || 0,
                      ),
                    },
                  },
                };
              }

              return tableData;
            },
          ),
        },
      } as ArticleEntryData;
    },
    "Additional Information": (data, draggedIndexInfo) => {
      return {
        ...data,
        Deals: {
          ...data.Deals,
          "Additional Information (Deal)": {
            ...data.Deals?.["Additional Information (Deal)"],
            "Additional Information": {
              ...data.Deals?.["Additional Information (Deal)"]?.[
                "Additional Information"
              ],
              value: moveItemToPosition(
                data.Deals?.["Additional Information (Deal)"]?.[
                  "Additional Information"
                ].value || [],
                draggedIndexInfo[0] || 0,
                draggedIndexInfo[1] || 0,
              ),
            },
          },
        },
      } as ArticleEntryData;
    },
    "Purpose of the Deal": (data, draggedIndexInfo) => {
      return {
        ...data,
        Deals: {
          ...data.Deals,
          "Additional Information (Deal)": {
            ...data.Deals?.["Additional Information (Deal)"],
            "Purpose of the Deal": {
              ...data.Deals?.["Additional Information (Deal)"]?.[
                "Purpose of the Deal"
              ],
              value: moveItemToPosition(
                data.Deals?.["Additional Information (Deal)"]?.[
                  "Purpose of the Deal"
                ].value || [],
                draggedIndexInfo[0] || 0,
                draggedIndexInfo[1] || 0,
              ),
            },
          },
        },
      } as ArticleEntryData;
    },
    [MERGE_ACQUISITION_SHELL_KEYS.mergerFinancialTermsMa]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) => {
      return {
        ...data,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...data[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: data.Deals?.[
            ENTRY_DATA_LICENSEE_TABLE
          ]?.map((tableData, currentTableIndex) => {
            if (currentTableIndex === positionIndex?.tableIndex) {
              return {
                ...tableData,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms]: {
                  ...tableData[
                    ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                  ],
                  [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.mergerFinancialTermsMa]:
                    {
                      ...tableData[
                        ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                      ][
                        ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                          .mergerFinancialTermsMa
                      ],
                      value: moveItemToPosition(
                        tableData[
                          ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                        ][
                          ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                            .mergerFinancialTermsMa
                        ].value,
                        draggedIndexInfo[0] || 0,
                        draggedIndexInfo[1] || 0,
                      ),
                    },
                },
              };
            }

            return tableData;
          }),
        },
      } as ArticleEntryData;
    },
  },
  [SECTION_KEYS.clinicalTrial]: {
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.brandName]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.clinicalTrial,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.brandName,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.innName]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.clinicalTrial,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.innName,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.codeName]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.clinicalTrial,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.codeName,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.roa]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.clinicalTrial,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.roa,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.modality]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.clinicalTrial,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.modality,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.target]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.clinicalTrial,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.target,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.moa]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.clinicalTrial,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.moa,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.targetIndication]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.clinicalTrial,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.targetIndication,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.therapeuticArea]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.clinicalTrial,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.therapeuticArea,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.otherCharacteristics]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.clinicalTrial,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.otherCharacteristics,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.originatorDeveloper]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.clinicalTrial,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.originatorDeveloper,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.phase]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.clinicalTrial,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.phase,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.refProductName]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.clinicalTrial,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.refProductName,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.clinicalDesignPhase]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) => {
      return {
        ...data,
        [ENTRY_DATA_SECTION_KEYS.clinicalTrial]: {
          ...data[ENTRY_DATA_SECTION_KEYS.clinicalTrial],
          [ENTRY_DATA_CLINICAL_TRIAL_DESIGN]: data[
            ENTRY_DATA_SECTION_KEYS.clinicalTrial
          ]?.[ENTRY_DATA_CLINICAL_TRIAL_DESIGN]?.map(
            (tableInfo, tableInfoIndex) => {
              if (positionIndex?.tableIndex === tableInfoIndex)
                return {
                  ...tableInfo,
                  [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1]:
                    {
                      ...tableInfo[
                        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                          .clinicalTrialDesign1
                      ],
                      [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.clinicalDesignPhase]:
                        {
                          ...tableInfo[
                            ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                              .clinicalTrialDesign1
                          ][
                            ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS
                              .clinicalDesignPhase
                          ],
                          value: moveItemToPosition(
                            tableInfo[
                              ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                                .clinicalTrialDesign1
                            ][
                              ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS
                                .clinicalDesignPhase
                            ].value || [],
                            draggedIndexInfo[0] || 0,
                            draggedIndexInfo[1] || 0,
                          ),
                        },
                    },
                };

              return tableInfo;
            },
          ),
        },
      };
    },
    [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.inclusionCriteriaDiagnostic]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) => {
      return {
        ...data,
        [ENTRY_DATA_SECTION_KEYS.clinicalTrial]: {
          ...data[ENTRY_DATA_SECTION_KEYS.clinicalTrial],
          [ENTRY_DATA_CLINICAL_TRIAL_DESIGN]: data[
            ENTRY_DATA_SECTION_KEYS.clinicalTrial
          ]?.[ENTRY_DATA_CLINICAL_TRIAL_DESIGN]?.map(
            (tableInfo, tableInfoIndex) => {
              if (positionIndex?.tableIndex === tableInfoIndex)
                return {
                  ...tableInfo,
                  [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1]:
                    {
                      ...tableInfo[
                        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                          .clinicalTrialDesign1
                      ],
                      [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.inclusionCriteriaDiagnostic]:
                        {
                          ...tableInfo[
                            ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                              .clinicalTrialDesign1
                          ][
                            ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS
                              .inclusionCriteriaDiagnostic
                          ],
                          value: moveItemToPosition(
                            tableInfo[
                              ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                                .clinicalTrialDesign1
                            ][
                              ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS
                                .inclusionCriteriaDiagnostic
                            ].value || [],
                            draggedIndexInfo[0] || 0,
                            draggedIndexInfo[1] || 0,
                          ),
                        },
                    },
                };

              return tableInfo;
            },
          ),
        },
      };
    },
    [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.inclusionCriteriaDetail]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) => {
      return {
        ...data,
        [ENTRY_DATA_SECTION_KEYS.clinicalTrial]: {
          ...data[ENTRY_DATA_SECTION_KEYS.clinicalTrial],
          [ENTRY_DATA_CLINICAL_TRIAL_DESIGN]: data[
            ENTRY_DATA_SECTION_KEYS.clinicalTrial
          ]?.[ENTRY_DATA_CLINICAL_TRIAL_DESIGN]?.map(
            (tableInfo, tableInfoIndex) => {
              if (positionIndex?.tableIndex === tableInfoIndex)
                return {
                  ...tableInfo,
                  [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1]:
                    {
                      ...tableInfo[
                        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                          .clinicalTrialDesign1
                      ],
                      [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.inclusionCriteriaDetail]:
                        {
                          ...tableInfo[
                            ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                              .clinicalTrialDesign1
                          ][
                            ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS
                              .inclusionCriteriaDetail
                          ],
                          value: moveItemToPosition(
                            tableInfo[
                              ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                                .clinicalTrialDesign1
                            ][
                              ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS
                                .inclusionCriteriaDetail
                            ].value || [],
                            draggedIndexInfo[0] || 0,
                            draggedIndexInfo[1] || 0,
                          ),
                        },
                    },
                };

              return tableInfo;
            },
          ),
        },
      };
    },
    [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.trialName]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) => {
      return {
        ...data,
        [ENTRY_DATA_SECTION_KEYS.clinicalTrial]: {
          ...data[ENTRY_DATA_SECTION_KEYS.clinicalTrial],
          [ENTRY_DATA_CLINICAL_TRIAL_DESIGN]: data[
            ENTRY_DATA_SECTION_KEYS.clinicalTrial
          ]?.[ENTRY_DATA_CLINICAL_TRIAL_DESIGN]?.map(
            (tableInfo, tableInfoIndex) => {
              if (positionIndex?.tableIndex === tableInfoIndex)
                return {
                  ...tableInfo,
                  [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1]:
                    {
                      ...tableInfo[
                        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                          .clinicalTrialDesign1
                      ],
                      [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.trialName]: {
                        ...tableInfo[
                          ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                            .clinicalTrialDesign1
                        ][ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.trialName],
                        value: moveItemToPosition(
                          tableInfo[
                            ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                              .clinicalTrialDesign1
                          ][ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.trialName]
                            .value || [],
                          draggedIndexInfo[0] || 0,
                          draggedIndexInfo[1] || 0,
                        ),
                      },
                    },
                };

              return tableInfo;
            },
          ),
        },
      };
    },
    [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.nctName]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) => {
      return {
        ...data,
        [ENTRY_DATA_SECTION_KEYS.clinicalTrial]: {
          ...data[ENTRY_DATA_SECTION_KEYS.clinicalTrial],
          [ENTRY_DATA_CLINICAL_TRIAL_DESIGN]: data[
            ENTRY_DATA_SECTION_KEYS.clinicalTrial
          ]?.[ENTRY_DATA_CLINICAL_TRIAL_DESIGN]?.map(
            (tableInfo, tableInfoIndex) => {
              if (positionIndex?.tableIndex === tableInfoIndex)
                return {
                  ...tableInfo,
                  [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1]:
                    {
                      ...tableInfo[
                        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                          .clinicalTrialDesign1
                      ],
                      [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.nctName]: {
                        ...tableInfo[
                          ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                            .clinicalTrialDesign1
                        ][ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.nctName],
                        value: moveItemToPosition(
                          tableInfo[
                            ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                              .clinicalTrialDesign1
                          ][ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.nctName]
                            .value || [],
                          draggedIndexInfo[0] || 0,
                          draggedIndexInfo[1] || 0,
                        ),
                      },
                    },
                };

              return tableInfo;
            },
          ),
        },
      };
    },
    [ENTRY_DATA_CLINICAL_RESULTS_KEYS.resultsClassification]: (
      data,
      draggedIndexInfo,
    ) => {
      return {
        ...data,
        [ENTRY_DATA_SECTION_KEYS.clinicalTrial]: {
          ...data[ENTRY_DATA_SECTION_KEYS.clinicalTrial],
          [ENTRY_DATA_CLINICAL_RESULTS]: {
            ...data?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
              ENTRY_DATA_CLINICAL_RESULTS
            ],
            [ENTRY_DATA_CLINICAL_RESULTS_KEYS.resultsClassification]: {
              ...data?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
                ENTRY_DATA_CLINICAL_RESULTS
              ]?.[ENTRY_DATA_CLINICAL_RESULTS_KEYS.resultsClassification],
              value: moveItemToPosition(
                data?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
                  ENTRY_DATA_CLINICAL_RESULTS
                ]?.[ENTRY_DATA_CLINICAL_RESULTS_KEYS.resultsClassification]
                  .value || [],
                draggedIndexInfo[0] || 0,
                draggedIndexInfo[1] || 0,
              ),
            },
          },
        },
      } as ArticleEntryData;
    },
    [ENTRY_DATA_CLINICAL_ENDPOINTS_DESCRIPTION_KEYS.endpointDescription]: (
      data,
      draggedIndexInfo,
    ) => {
      return {
        ...data,
        [ENTRY_DATA_SECTION_KEYS.clinicalTrial]: {
          ...data[ENTRY_DATA_SECTION_KEYS.clinicalTrial],
          [ENTRY_DATA_CLINICAL_ENDPOINTS_DESCRIPTION]: {
            ...data?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
              ENTRY_DATA_CLINICAL_ENDPOINTS_DESCRIPTION
            ],
            [ENTRY_DATA_CLINICAL_ENDPOINTS_DESCRIPTION_KEYS.endpointDescription]:
              {
                ...data?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
                  ENTRY_DATA_CLINICAL_ENDPOINTS_DESCRIPTION
                ]?.[
                  ENTRY_DATA_CLINICAL_ENDPOINTS_DESCRIPTION_KEYS
                    .endpointDescription
                ],
                value: moveItemToPosition(
                  data?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
                    ENTRY_DATA_CLINICAL_ENDPOINTS_DESCRIPTION
                  ]?.[
                    ENTRY_DATA_CLINICAL_ENDPOINTS_DESCRIPTION_KEYS
                      .endpointDescription
                  ].value || [],
                  draggedIndexInfo[0] || 0,
                  draggedIndexInfo[1] || 0,
                ),
              },
          },
        },
      } as ArticleEntryData;
    },
  },
  [SECTION_KEYS.regulatory]: {
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.brandName]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.regulatory,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.brandName,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.innName]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.regulatory,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.innName,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.codeName]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.regulatory,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.codeName,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.roa]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.regulatory,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.roa,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.modality]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.regulatory,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.modality,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.target]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.regulatory,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.target,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.moa]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.regulatory,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.moa,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.targetIndication]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.regulatory,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.targetIndication,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.therapeuticArea]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.regulatory,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.therapeuticArea,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.otherCharacteristics]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.regulatory,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.otherCharacteristics,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.phase]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.regulatory,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.phase,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.refProductName]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.regulatory,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.refProductName,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.originatorDeveloper]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.regulatory,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.originatorDeveloper,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_APPROVAL_PROCESS_KEYS.authorizedCompany]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) => {
      return {
        ...data,
        [ENTRY_DATA_SECTION_KEYS.regulatory]: {
          ...data[ENTRY_DATA_SECTION_KEYS.regulatory],
          [ENTRY_DATA_APPROVAL_PROCESS_CONTENTS]: {
            ...data?.[ENTRY_DATA_SECTION_KEYS.regulatory]?.[
              ENTRY_DATA_APPROVAL_PROCESS_CONTENTS
            ],
            [ENTRY_DATA_APPROVAL_PROCESS_CONTENTS_KEYS.approvalProcess]: data?.[
              ENTRY_DATA_SECTION_KEYS.regulatory
            ]?.[ENTRY_DATA_APPROVAL_PROCESS_CONTENTS]?.[
              ENTRY_DATA_APPROVAL_PROCESS_CONTENTS_KEYS.approvalProcess
            ]?.map((groupShellData, groupShellIndex) => {
              if (positionIndex?.groupShellIndex === groupShellIndex) {
                return {
                  ...groupShellData,
                  [ENTRY_DATA_APPROVAL_PROCESS_KEYS.authorizedCompany]: {
                    ...groupShellData[
                      ENTRY_DATA_APPROVAL_PROCESS_KEYS.authorizedCompany
                    ],
                    value: moveItemToPosition(
                      groupShellData[
                        ENTRY_DATA_APPROVAL_PROCESS_KEYS.authorizedCompany
                      ].value || [],
                      draggedIndexInfo[0] || 0,
                      draggedIndexInfo[1] || 0,
                    ),
                  },
                };
              }

              return groupShellData;
            }),
          },
        },
      };
    },
    [ENTRY_DATA_APPROVAL_PROCESS_KEYS.indicationOnLabel]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) => {
      return {
        ...data,
        [ENTRY_DATA_SECTION_KEYS.regulatory]: {
          ...data[ENTRY_DATA_SECTION_KEYS.regulatory],
          [ENTRY_DATA_APPROVAL_PROCESS_CONTENTS]: {
            ...data?.[ENTRY_DATA_SECTION_KEYS.regulatory]?.[
              ENTRY_DATA_APPROVAL_PROCESS_CONTENTS
            ],
            [ENTRY_DATA_APPROVAL_PROCESS_CONTENTS_KEYS.approvalProcess]: data?.[
              ENTRY_DATA_SECTION_KEYS.regulatory
            ]?.[ENTRY_DATA_APPROVAL_PROCESS_CONTENTS]?.[
              ENTRY_DATA_APPROVAL_PROCESS_CONTENTS_KEYS.approvalProcess
            ]?.map((groupShellData, groupShellIndex) => {
              if (positionIndex?.groupShellIndex === groupShellIndex) {
                return {
                  ...groupShellData,
                  [ENTRY_DATA_APPROVAL_PROCESS_KEYS.indicationOnLabel]: {
                    ...groupShellData[
                      ENTRY_DATA_APPROVAL_PROCESS_KEYS.indicationOnLabel
                    ],
                    value: moveItemToPosition(
                      groupShellData[
                        ENTRY_DATA_APPROVAL_PROCESS_KEYS.indicationOnLabel
                      ].value || [],
                      draggedIndexInfo[0] || 0,
                      draggedIndexInfo[1] || 0,
                    ),
                  },
                };
              }

              return groupShellData;
            }),
          },
        },
      };
    },
    [ENTRY_DATA_APPROVAL_PROCESS_CONTENTS_KEYS.additionalInformationOnTheApprovalProcess]:
      (data, draggedIndexInfo) => {
        return {
          ...data,
          [ENTRY_DATA_SECTION_KEYS.regulatory]: {
            ...data[ENTRY_DATA_SECTION_KEYS.regulatory],
            [ENTRY_DATA_APPROVAL_PROCESS_CONTENTS]: {
              ...data?.[ENTRY_DATA_SECTION_KEYS.regulatory]?.[
                ENTRY_DATA_APPROVAL_PROCESS_CONTENTS
              ],
              [ENTRY_DATA_APPROVAL_PROCESS_CONTENTS_KEYS.additionalInformationOnTheApprovalProcess]:
                {
                  ...data?.[ENTRY_DATA_SECTION_KEYS.regulatory]?.[
                    ENTRY_DATA_APPROVAL_PROCESS_CONTENTS
                  ]?.[
                    ENTRY_DATA_APPROVAL_PROCESS_CONTENTS_KEYS
                      .additionalInformationOnTheApprovalProcess
                  ],
                  value: moveItemToPosition(
                    data?.[ENTRY_DATA_SECTION_KEYS.regulatory]?.[
                      ENTRY_DATA_APPROVAL_PROCESS_CONTENTS
                    ]?.[
                      ENTRY_DATA_APPROVAL_PROCESS_CONTENTS_KEYS
                        .additionalInformationOnTheApprovalProcess
                    ]?.value || [],
                    draggedIndexInfo[0] || 0,
                    draggedIndexInfo[1] || 0,
                  ),
                },
            },
          },
        } as ArticleEntryData;
      },
  },
  [SECTION_KEYS.ventureCapital]: {
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.brandName]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.ventureCapital,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.brandName,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.innName]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.ventureCapital,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.innName,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.codeName]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.ventureCapital,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.codeName,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.roa]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.ventureCapital,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.roa,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.modality]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.ventureCapital,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.modality,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.target]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.ventureCapital,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.target,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.moa]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.ventureCapital,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.moa,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.targetIndication]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.ventureCapital,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.targetIndication,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.therapeuticArea]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.ventureCapital,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.therapeuticArea,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.otherCharacteristics]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.ventureCapital,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.otherCharacteristics,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.phase]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.ventureCapital,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.phase,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.refProductName]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.ventureCapital,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.refProductName,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.originatorDeveloper]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.ventureCapital,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.originatorDeveloper,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_ROUND_TYPE_AND_STATUS_KEYS.roundType]: (
      data,
      draggedIndexInfo,
    ) => {
      return {
        ...data,
        [ENTRY_DATA_SECTION_KEYS.ventureCapital]: {
          ...data[ENTRY_DATA_SECTION_KEYS.ventureCapital],
          [ENTRY_DATA_ROUND_TYPE_AND_STATUS_GROUP_TABLE_KEY]: {
            ...data?.[ENTRY_DATA_SECTION_KEYS.ventureCapital]?.[
              ENTRY_DATA_ROUND_TYPE_AND_STATUS_GROUP_TABLE_KEY
            ],
            [ENTRY_DATA_ROUND_TYPE_AND_STATUS_KEYS.roundType]: {
              ...data?.[ENTRY_DATA_SECTION_KEYS.ventureCapital]?.[
                ENTRY_DATA_ROUND_TYPE_AND_STATUS_GROUP_TABLE_KEY
              ]?.[ENTRY_DATA_ROUND_TYPE_AND_STATUS_KEYS.roundType],
              value: moveItemToPosition(
                data?.[ENTRY_DATA_SECTION_KEYS.ventureCapital]?.[
                  ENTRY_DATA_ROUND_TYPE_AND_STATUS_GROUP_TABLE_KEY
                ]?.[ENTRY_DATA_ROUND_TYPE_AND_STATUS_KEYS.roundType]?.value ||
                  [],
                draggedIndexInfo[0] || 0,
                draggedIndexInfo[1] || 0,
              ),
            },
          },
        },
      } as ArticleEntryData;
    },
    [ENTRY_DATA_FUNDED_COMPANY_SHELL_KEYS.companyDescription]: (
      data,
      draggedIndexInfo,
    ) => {
      return {
        ...data,
        [ENTRY_DATA_SECTION_KEYS.ventureCapital]: {
          ...data[ENTRY_DATA_SECTION_KEYS.ventureCapital],
          [ENTRY_DATA_FUNDED_COMPANY_GROUP_TABLE_KEY]: {
            ...data?.[ENTRY_DATA_SECTION_KEYS.ventureCapital]?.[
              ENTRY_DATA_FUNDED_COMPANY_GROUP_TABLE_KEY
            ],
            [ENTRY_DATA_FUNDED_COMPANY_SHELL_KEYS.companyDescription]: {
              ...data?.[ENTRY_DATA_SECTION_KEYS.ventureCapital]?.[
                ENTRY_DATA_FUNDED_COMPANY_GROUP_TABLE_KEY
              ]?.[ENTRY_DATA_FUNDED_COMPANY_SHELL_KEYS.companyDescription],
              value: moveItemToPosition(
                data?.[ENTRY_DATA_SECTION_KEYS.ventureCapital]?.[
                  ENTRY_DATA_FUNDED_COMPANY_GROUP_TABLE_KEY
                ]?.[ENTRY_DATA_FUNDED_COMPANY_SHELL_KEYS.companyDescription]
                  ?.value || [],
                draggedIndexInfo[0] || 0,
                draggedIndexInfo[1] || 0,
              ),
            },
          },
        },
      } as ArticleEntryData;
    },
    [ENTRY_DATA_FINANCING_INFORMATION_SHELL_KEYS.financingPurpose]: (
      data,
      draggedIndexInfo,
    ) => {
      return {
        ...data,
        [ENTRY_DATA_SECTION_KEYS.ventureCapital]: {
          ...data[ENTRY_DATA_SECTION_KEYS.ventureCapital],
          [ENTRY_DATA_FINANCING_INFORMATION_GROUP_TABLE_KEY]: {
            ...data?.[ENTRY_DATA_SECTION_KEYS.ventureCapital]?.[
              ENTRY_DATA_FINANCING_INFORMATION_GROUP_TABLE_KEY
            ],
            [ENTRY_DATA_FINANCING_INFORMATION_SHELL_KEYS.financingPurpose]: {
              ...data?.[ENTRY_DATA_SECTION_KEYS.ventureCapital]?.[
                ENTRY_DATA_FINANCING_INFORMATION_GROUP_TABLE_KEY
              ]?.[ENTRY_DATA_FINANCING_INFORMATION_SHELL_KEYS.financingPurpose],
              value: moveItemToPosition(
                data?.[ENTRY_DATA_SECTION_KEYS.ventureCapital]?.[
                  ENTRY_DATA_FINANCING_INFORMATION_GROUP_TABLE_KEY
                ]?.[
                  ENTRY_DATA_FINANCING_INFORMATION_SHELL_KEYS.financingPurpose
                ]?.value || [],
                draggedIndexInfo[0] || 0,
                draggedIndexInfo[1] || 0,
              ),
            },
          },
        },
      } as ArticleEntryData;
    },
    [ENTRY_DATA_FINANCING_INFORMATION_SHELL_KEYS.financingInformationDetail]: (
      data,
      draggedIndexInfo,
    ) => {
      return {
        ...data,
        [ENTRY_DATA_SECTION_KEYS.ventureCapital]: {
          ...data[ENTRY_DATA_SECTION_KEYS.ventureCapital],
          [ENTRY_DATA_FINANCING_INFORMATION_GROUP_TABLE_KEY]: {
            ...data?.[ENTRY_DATA_SECTION_KEYS.ventureCapital]?.[
              ENTRY_DATA_FINANCING_INFORMATION_GROUP_TABLE_KEY
            ],
            [ENTRY_DATA_FINANCING_INFORMATION_SHELL_KEYS.financingInformationDetail]:
              {
                ...data?.[ENTRY_DATA_SECTION_KEYS.ventureCapital]?.[
                  ENTRY_DATA_FINANCING_INFORMATION_GROUP_TABLE_KEY
                ]?.[
                  ENTRY_DATA_FINANCING_INFORMATION_SHELL_KEYS
                    .financingInformationDetail
                ],
                value: moveItemToPosition(
                  data?.[ENTRY_DATA_SECTION_KEYS.ventureCapital]?.[
                    ENTRY_DATA_FINANCING_INFORMATION_GROUP_TABLE_KEY
                  ]?.[
                    ENTRY_DATA_FINANCING_INFORMATION_SHELL_KEYS
                      .financingInformationDetail
                  ]?.value || [],
                  draggedIndexInfo[0] || 0,
                  draggedIndexInfo[1] || 0,
                ),
              },
          },
        },
      } as ArticleEntryData;
    },
    [INVESTORS_SHELL_KEYS.investorName]: (data, draggedIndexInfo) => {
      return {
        ...data,
        [SECTION_KEYS.ventureCapital]: {
          ...data?.[SECTION_KEYS.ventureCapital],
          [INVESTORS]: {
            ...data?.[SECTION_KEYS.ventureCapital]?.[INVESTORS],
            [INVESTORS_SHELL_KEYS.investorName]: {
              ...data?.[SECTION_KEYS.ventureCapital]?.[INVESTORS]?.[
                INVESTORS_SHELL_KEYS.investorName
              ],
              value: moveItemToPosition(
                data?.[SECTION_KEYS.ventureCapital]?.[INVESTORS]?.[
                  INVESTORS_SHELL_KEYS.investorName
                ].value || [],
                draggedIndexInfo[0] || 0,
                draggedIndexInfo[1] || 0,
              ),
            },
          },
        },
      } as ArticleEntryData;
    },
    [ENTRY_DATA_ADDITIONAL_INFORMATION_VENTURE_CAPITAL_KEYS.additionalInformation]:
      (data, draggedIndexInfo) => {
        return {
          ...data,
          [ENTRY_DATA_SECTION_KEYS.ventureCapital]: {
            ...data[ENTRY_DATA_SECTION_KEYS.ventureCapital],
            [ENTRY_DATA_ADDITIONAL_INFORMATION_VENTURE_CAPITAL_GROUP_TABLE_KEY]:
              {
                ...data?.[ENTRY_DATA_SECTION_KEYS.ventureCapital]?.[
                  ENTRY_DATA_ADDITIONAL_INFORMATION_VENTURE_CAPITAL_GROUP_TABLE_KEY
                ],
                [ENTRY_DATA_ADDITIONAL_INFORMATION_VENTURE_CAPITAL_KEYS.additionalInformation]:
                  {
                    ...data?.[ENTRY_DATA_SECTION_KEYS.ventureCapital]?.[
                      ENTRY_DATA_ADDITIONAL_INFORMATION_VENTURE_CAPITAL_GROUP_TABLE_KEY
                    ]?.[
                      ENTRY_DATA_ADDITIONAL_INFORMATION_VENTURE_CAPITAL_KEYS
                        .additionalInformation
                    ],
                    value: moveItemToPosition(
                      data?.[ENTRY_DATA_SECTION_KEYS.ventureCapital]?.[
                        ENTRY_DATA_ADDITIONAL_INFORMATION_VENTURE_CAPITAL_GROUP_TABLE_KEY
                      ]?.[
                        ENTRY_DATA_ADDITIONAL_INFORMATION_VENTURE_CAPITAL_KEYS
                          .additionalInformation
                      ]?.value || [],
                      draggedIndexInfo[0] || 0,
                      draggedIndexInfo[1] || 0,
                    ),
                  },
              },
          },
        } as ArticleEntryData;
      },
  },
  [SECTION_KEYS.expeditedProgram]: {
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.brandName]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.expeditedProgram,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.brandName,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.innName]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.expeditedProgram,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.innName,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.codeName]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.expeditedProgram,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.codeName,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.roa]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.expeditedProgram,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.roa,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.modality]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.expeditedProgram,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.modality,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.target]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.expeditedProgram,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.target,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.therapeuticArea]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.expeditedProgram,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.therapeuticArea,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.moa]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.expeditedProgram,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.moa,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.targetIndication]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.expeditedProgram,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.targetIndication,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.otherCharacteristics]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.expeditedProgram,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.otherCharacteristics,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.phase]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.expeditedProgram,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.phase,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.refProductName]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.expeditedProgram,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.refProductName,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.originatorDeveloper]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.expeditedProgram,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.originatorDeveloper,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_EXPEDITED_PROGRAM_CONTENTS_KEYS.expeditedProgram]: (
      data,
      draggedIndexInfo,
    ) => {
      return {
        ...data,
        [SECTION_KEYS.expeditedProgram]: {
          ...data[SECTION_KEYS.expeditedProgram],
          [ENTRY_DATA_EXPEDITED_PROGRAM_CONTENTS]: {
            ...data[SECTION_KEYS.expeditedProgram]?.[
              ENTRY_DATA_EXPEDITED_PROGRAM_CONTENTS
            ],
            [ENTRY_DATA_EXPEDITED_PROGRAM_CONTENTS_KEYS.expeditedProgram]: {
              ...data[SECTION_KEYS.expeditedProgram]?.[
                ENTRY_DATA_EXPEDITED_PROGRAM_CONTENTS
              ]?.[ENTRY_DATA_EXPEDITED_PROGRAM_CONTENTS_KEYS.expeditedProgram],
              value: moveItemToPosition(
                data[SECTION_KEYS.expeditedProgram]?.[
                  ENTRY_DATA_EXPEDITED_PROGRAM_CONTENTS
                ]?.[ENTRY_DATA_EXPEDITED_PROGRAM_CONTENTS_KEYS.expeditedProgram]
                  ?.value || [],
                draggedIndexInfo[0] || 0,
                draggedIndexInfo[1] || 0,
              ),
            },
          },
        },
      } as ArticleEntryData;
    },
    [ENTRY_DATA_EXPEDITED_PROGRAM_CONTENTS_KEYS.additionalInformationOnExpeditedProgram]:
      (data, draggedIndexInfo) => {
        return {
          ...data,
          [SECTION_KEYS.expeditedProgram]: {
            ...data[SECTION_KEYS.expeditedProgram],
            [ENTRY_DATA_EXPEDITED_PROGRAM_CONTENTS]: {
              ...data[SECTION_KEYS.expeditedProgram]?.[
                ENTRY_DATA_EXPEDITED_PROGRAM_CONTENTS
              ],
              [ENTRY_DATA_EXPEDITED_PROGRAM_CONTENTS_KEYS.additionalInformationOnExpeditedProgram]:
                {
                  ...data[SECTION_KEYS.expeditedProgram]?.[
                    ENTRY_DATA_EXPEDITED_PROGRAM_CONTENTS
                  ]?.[
                    ENTRY_DATA_EXPEDITED_PROGRAM_CONTENTS_KEYS
                      .additionalInformationOnExpeditedProgram
                  ],
                  value: moveItemToPosition(
                    data[SECTION_KEYS.expeditedProgram]?.[
                      ENTRY_DATA_EXPEDITED_PROGRAM_CONTENTS
                    ]?.[
                      ENTRY_DATA_EXPEDITED_PROGRAM_CONTENTS_KEYS
                        .additionalInformationOnExpeditedProgram
                    ]?.value || [],
                    draggedIndexInfo[0] || 0,
                    draggedIndexInfo[1] || 0,
                  ),
                },
            },
          },
        } as ArticleEntryData;
      },
  },
  [SECTION_KEYS.clinicalTrialStatus]: {
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.brandName]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.clinicalTrialStatus,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.brandName,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.innName]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.clinicalTrialStatus,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.innName,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.codeName]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.clinicalTrialStatus,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.codeName,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.roa]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.clinicalTrialStatus,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.roa,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.modality]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.clinicalTrialStatus,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.modality,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.therapeuticArea]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.clinicalTrialStatus,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.therapeuticArea,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.target]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.clinicalTrialStatus,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.target,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.moa]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.clinicalTrialStatus,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.moa,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.targetIndication]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.clinicalTrialStatus,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.targetIndication,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.otherCharacteristics]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.clinicalTrialStatus,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.otherCharacteristics,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.originatorDeveloper]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.clinicalTrialStatus,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.originatorDeveloper,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.phase]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.clinicalTrialStatus,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.phase,
        tableIndex: positionIndex?.tableIndex,
      }),
    [ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.refProductName]: (
      data,
      draggedIndexInfo,
      positionIndex,
    ) =>
      dragChangeDrugProfileValueOrder({
        data,
        draggedIndexInfo,
        articleSectionKey: ENTRY_DATA_SECTION_KEYS.clinicalTrialStatus,
        shellKey: ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS.refProductName,
        tableIndex: positionIndex?.tableIndex,
      }),
  },
};
