export type UnsuitableReasonType = "default";

export interface UnsuitableReasonList {
  id: string;
  reason: string | null;
  placeholder?: string;
  explanation?: string;
  defaultReason?: string;
  duplId?: number | null;
  type: UnsuitableReasonType;
  isTarget: boolean;
}

export const DEFAULT_VALUE_FOR_ADD_ID_TYPE = "Overlapped press";

export const UNSUITABLE_REASON_TYPE_INFO: Record<string, UnsuitableReasonType> =
  {
    default: "default",
  };

export const UNSUITABLE_REASON_LIST: UnsuitableReasonList[] = [
  {
    id: "S930-08",
    reason: "venture formation (spin out, joint venture)",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-16",
    reason: "Opening new round",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-009",
    reason: "three party deal",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-015",
    reason: "comment or celebration on deal",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-10",
    reason: "closing, completion",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-11",
    reason: "update, amendment, termination, term sheet, LOI",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-00",
    reason: "To be entered later",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-01",
    reason: "Missing press content",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-02",
    reason: "Not enough information",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-03",
    reason: "Out of scope company",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-04",
    reason: "Out of scope press",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-05",
    reason: "Second target company",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-06",
    reason: "Second target press",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
  {
    id: "S930-07",
    reason: "Non-english",
    type: UNSUITABLE_REASON_TYPE_INFO.default,
    isTarget: false,
  },
];
